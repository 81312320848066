import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Checkbox,
  Dropdown,
  Grid,
  Icon,
  Image,
  Input,
  Button,
  Header,
  Modal,
  Loader,
  Message,
  Select,
  Label,
  Dimmer,
} from "semantic-ui-react";
import "./hoseMaker.css";
import MetaData from "../../components/MetaData";
import rightArrow from "../../../src/assets/images/right-transparent.png";
import "../Search/narrowFilters.css";
import SearchComponent from "../../containers/Search";
import {
  apiGetHosePositions,
  apiGetProduct,
  apiUploadHoseMakerImage,
  apiRemoveHoseMakerImage,
  apiUploadQuotingHoseMakerImage,
} from "./hoseMaker.api";
import NumericInputBox from "../../components/NumericInputBox";
import _, { set } from "lodash";
import productDefaultImage from "../../assets/images/product_default.png";
import ImageUploader from "./imageUploader";
import { hoseMakerTempData } from "./data";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import noImageAvailable from "../../assets/images/no_image_available.png";
import certificateIcon from "../../assets/images/certificate.svg";
import certificateUploadIcon from "../../assets/images/certificate-upload.svg";
import HoseTrainingCertificate from "./HoseTestingCertificate";
import {
  apiGetJobbing,
  apiGetCauseTypes,
  apiGetDamageTypes,
} from "../Jobbing/jobbing.api";
import HoseMakerImageUploader from "./hoseMakerImageUploader";
import { apiGetQuoting } from "../Quoting/quoting.api";

const HoseMakerNew = (props) => {
  const {
    data,
    currentStatus,
    isAssemble,
    hoseMakerIndex,
    isQuoting = false,
  } = props;
  const [formData, setFormData] = useState({
    step1_Hose: 0,
    step1_Barcode: "",
    step1_Name: "",
    step2_LHF: 0,
    step2_Barcode: "",
    step2_Name: "",
    step3_LFerrule: 0,
    step3_Barcode: "",
    step3_Name: "",
    step4_RHF: 0,
    step4_Barcode: "",
    step4_Name: "",
    step5_RFerrule: 0,
    step5_Barcode: "",
    step5_Name: "",
    quantity: 1,
    orientation: null,
    orientationString: "",
    oal: null,
    oalString: "",
    positionId: 0,
    others: [],
    lfActualCrimpSize: 0,
    rfActualCrimpSize: 0,
    isMarkedPushedOnCorrectly: false,
    bomAssembly: {
      lfActualCrimpSize: 0,
      rfActualCrimpSize: 0,
      isMarkedPushedOnCorrectly: false,
      image1: "",
      image2: "",
      image3: "",
      image4: "",
    },
    bomAssemblyImages: [],
  });
  const [locationList, setLocationList] = useState([]);
  const [selectedHose, setSelectedHose] = useState({});
  const [selectedFitting, setSelectedFitting] = useState({});
  const [selectedFitting2, setSelectedFitting2] = useState({});
  const [selectedFerrule, setSelectedFerrule] = useState({});
  const [isLHFerrule, setIsLHFerrule] = useState(false);
  const [isRHFerrule, setIsRHFerrule] = useState(false);
  const [hasOrientation, setHasOrientation] = useState(false);
  const [step, setStep] = useState(1);
  const [viewStep, setViewStep] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState("");
  const [otherItemsList, setOtherItemsList] = useState([]);
  const [images, setImages] = useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [userCountryCode, setUserCountryCode] = useState("");

  // RS23
  const [viewTestingCertificate, setViewTestingCertificate] = useState(false);
  const [hoseTagData, setHoseTagData] = useState(null);
  const [causeTypes, setCauseTypes] = useState([]);
  const [damageTypes, setDamageTypes] = useState([]);

  // Hose maker URL image handling
  const [hoseMakerImages, setHoseMakerImages] = useState(
    data.hoseMaker[hoseMakerIndex]?.bomAssemblyImages
  );

  const [isUploadingHoseMakerImage, setIsUploadingHoseMakerImage] =
    useState(false);

  const uploadHoseMakerImage = async (imageData) => {
    var file = imageData[0];
    if (file) {
      if (file.size >= 25000000) {
        showErrorToast("File size cannot exceed 25MB.");
        return;
      }
      setIsUploadingHoseMakerImage(true);
      try {
        console.log("Uploading image", hoseMakerIndex);
        console.log("Uploading image", data.hoseMaker[hoseMakerIndex].seqId);
        let result = false;
        if (isQuoting) {
          result = await apiUploadQuotingHoseMakerImage(
            file,
            data.id,
            data.hoseMaker[hoseMakerIndex].seqId
          );
        } else {
          result = await apiUploadHoseMakerImage(
            file,
            data.id,
            data.hoseMaker[hoseMakerIndex].seqId
          );
        }
        if (result) {
          refreshImagesData();
          setIsUploadingHoseMakerImage(false);
        } else {
          showErrorToast("Failed to upload the image");
          setIsUploadingHoseMakerImage(false);
        }
      } catch {
        setIsUploadingHoseMakerImage(false);
        showErrorToast("Failed to upload the image");
      }
    }
  };

  const [deleteImagePopupOpen, setDeleteImagePopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const onDeleteHoseMakerImage = async () => {
    setIsLoading(true);
    const result = await apiRemoveHoseMakerImage(data.id, selectedImage.id);
    if (result) {
      showSuccessToast(
        <div>
          <i className="check icon" /> Image Deleted Successfully
        </div>
      );

      refreshImagesData();
      setIsLoading(false);
      setDeleteImagePopupOpen(false);
    } else {
      showErrorToast("Failed to delete image");
      setIsLoading(false);
    }
  };

  const refreshImagesData = async () => {
    let result = null;
    if (isQuoting) {
      result = await apiGetQuoting(data?.id);
    } else {
      result = await apiGetJobbing(data?.id);
    }
    props.refreshJobData(result);
    if (result) {
      setHoseMakerImages(result.hoseMaker[hoseMakerIndex]?.bomAssemblyImages);
    } else {
      showErrorToast("Something went wrong");
    }
  };

  // Additional values for OAL for inches
  const options = [
    { key: "00", text: "00", value: 0 },
    { key: "00", text: "1/4", value: 0.25 },
    { key: "00", text: "1/2", value: 0.5 },
    { key: "00", text: "3/4", value: 0.75 },
  ];

  const getCauseTypes = async () => {
    const result = await apiGetCauseTypes();
    if (result) {
      setCauseTypes(result);
    }
  };

  const getDamageTypes = async () => {
    const result = await apiGetDamageTypes();
    if (result) {
      setDamageTypes(result);
    }
  };

  const replacementList = [
    {
      key: 0,
      value: "Preventative Maintenance",
      text: "Preventative Maintenance",
    },
    {
      key: 1,
      value: "Reactive Repair",
      text: "Reactive Repair",
    },
  ];

  const onSetOAL = () => {
    if (formData.oal == 0 || formData.oal == null) {
      showErrorToast("Cut Length is required");
      return false;
    }
    return true;
  };

  const onSetOrientation = () => {
    if (
      hasOrientation &&
      (formData.orientation == 0 || formData.orientation == null)
    ) {
      showErrorToast("Orientation is required");
      return false;
    }
    return true;
  };

  const onConfirmBOM = (closeForm = false) => {
    if (formData.positionId == 0 && locationList.length > 0) {
      showErrorToast("Location is required");
      setViewStep(6);
      return false;
    }

    formData.bomAssembly.image1 = images.image1;
    formData.bomAssembly.image2 = images.image2;
    formData.bomAssembly.image3 = images.image3;
    formData.bomAssembly.image4 = images.image4;

    var currentDate = new Date();
    var utcDate = currentDate.toISOString();

    if (
      formData.lfActualCrimpSize > 0 &&
      formData.rfActualCrimpSize > 0 &&
      formData.isMarkedPushedOnCorrectly === true &&
      formData.bomAssembly.createdDateTime === null
    ) {
      formData.bomAssembly.createdBy = localStorage.getItem("user");
      formData.bomAssembly.createdDateTime = utcDate;
    }

    formData.bomAssembly.lastUpdatedDateTime = utcDate;

    // Compute total OAL
    formData.oal = formData.oal + formData.oalDecimal;

    if (props.hoseMakerIndex === null) {
      console.log("test");
      props.addBOM(formData);
      props.setHoseMakerIndex(data.hoseMaker.length - 1);
    } else {
      console.log("test");
      props.updateBOM(formData, props.hoseMakerIndex);
    }

    if (!closeForm) {
      setFormData({});
      props.onCancelHoseMaker();
    }
  };

  const handleKeyPressOAL = (e) => {
    // Prevent typing the period (.)
    if (e.key === ".") {
      e.preventDefault();
    }
  };

  function handleChange(dataType, value, index = 0) {
    setIsLoading(true);

    let newState = [];
    newState.push(formData);
    let hoseData = newState.map((item, i) => {
      if (i == 0) {
        if (!item.hoseIdTagging && item.quantity === 1) {
          item.hoseIdTagging = [
            {
              hoseId: "",
              hoseIdSequence: 0,
            },
          ];
        }
        if (dataType === "hose") {
          item.step1_Hose = value.id;
          item.step1_Barcode = value.stockCode;
          item.step1_Name = value.stockDescription;
          return item;
        }
        if (dataType === "fitting1") {
          item.step2_LHF = value.id;
          item.step2_Barcode = value.stockCode;
          item.step2_Name = value.stockDescription;
          return item;
        }
        if (dataType === "ferrule1") {
          item.step3_LFerrule = value.id;
          item.step3_Barcode = value.stockCode;
          item.step3_Name = value.stockDescription;
          return item;
        }
        if (dataType === "fitting2") {
          item.step4_RHF = value.id;
          item.step4_Barcode = value.stockCode;
          item.step4_Name = value.stockDescription;
          return item;
        }
        if (dataType === "ferrule2") {
          item.step5_RFerrule = value.id;
          item.step5_Barcode = value.stockCode;
          item.step5_Name = value.stockDescription;
          return item;
        }
        if (dataType === "lfActualCrimpSize") {
          item.bomAssembly.lfActualCrimpSize = Number(value);
          item.lfActualCrimpSize = Number(value);
          item.lfActualCrimpSizeString = value;
          return item;
        }
        if (dataType === "rfActualCrimpSize") {
          item.bomAssembly.rfActualCrimpSize = Number(value);
          item.rfActualCrimpSize = Number(value);
          item.rfActualCrimpSizeString = value;
          return item;
        }
        if (dataType === "isMarkedPushedOnCorrectly") {
          item.bomAssembly.isMarkedPushedOnCorrectly = value;
        }
        if (dataType === "oal") {
          if (!item.oalDecimal) item.oalDecimal = 0;
          if (/^\d+$/.test(value) || value === "") {
            item.oal = Number(value);
            item.oalString = value;
            return item;
          }
        }
        if (dataType === "oalDecimal") {
          item.oalDecimal = value;
          return item;
        }
        if (dataType === "orientation") {
          item.orientation = Number(value);
          item.orientationString = value;
          return item;
        }
        if (dataType === "quantity") {
          while (item.hoseIdTagging?.length < value) {
            item.hoseIdTagging.push({
              hoseId: "",
              hoseIdSequence: item.hoseIdTagging?.length,
            });
          }
          if (item.quantity > value) {
            item.hoseIdTagging.splice(value);
          }
        }
        if (dataType === "hoseIdTagging") {
          item.hoseIdTagging[index].hoseId = value;
          return item;
        }
        if (dataType === "damageType") {
          item.bomAssembly.damageType = value;
        }
        if (dataType === "causeType") {
          item.bomAssembly.causeType = value;
        }
        if (dataType === "hoseReplacementType") {
          item.bomAssembly.hoseReplacementType = value;
        }
        if (dataType === "hoseTestCertificate") {
          item.hoseIdTagging[index].testCertFilePath = value.filePath;
          item.hoseIdTagging[index].testCertFileName = value.fileName;
          return item;
        }
        if (dataType === "hoseTestCertificateDelete") {
          item.hoseIdTagging[index].testCertFilePath = null;
          item.hoseIdTagging[index].testCertFileName = null;
          return item;
        }
        return { ...item, [dataType]: value };
      }

      return item;
    });

    console.log(hoseData[0]);
    setFormData(hoseData[0]);
    setTimeout(function () {
      setIsLoading(false);
    }, 100);
  }

  function handleHoseIDChange(value, index = 0) {
    let newState = [];
    newState = formData.hoseIdTagging;

    let hoseIDData = newState.map((item, i) => {
      if (i === index) {
        item.hoseId = value;
        return item;
      }
      return item;
    });

    setFormData({ ...formData, hoseIdTagging: hoseIDData });
  }

  const getTestCertificate = async (file) => {
    handleChange("hoseTestCertificate", file, hoseTagData.hoseIdSequence);
  };

  const deleteTestCertificate = async () => {
    handleChange("hoseTestCertificateDelete", null, hoseTagData.hoseIdSequence);
  };

  async function handleBOMQuantityChange(index, value) {
    await setOtherItemsList([]);
    let newState = [];
    newState.push(formData);
    let hoseData = newState.map((item, i) => {
      if (i == 0) {
        item.others[index].quantity = value;
      }
      if (value == 0) {
        item.others.splice(index, 1);
      }
      return item;
    });

    setFormData(hoseData[0]);
    setOtherItemsList(hoseData[0].others);
  }

  const addOtherItems = async (product) => {
    await setOtherItemsList([]);
    const productData = {
      productId: product.id,
      quantity: 1,
      stockCode: product.stockCode,
      stockDescription: product.stockDescription,
      stockImage1: product.stockImage1,
      name: product.stockDescription,
      barcode: product.stockCode,
    };

    let newState = formData.others;
    const existing = newState.find(
      (element) => element.productId === product.id
    );

    if (!existing) newState.push(productData);

    setOtherItemsList(newState);
  };

  const onHoseClick = (hose) => {
    if (hose) {
      setSelectedHose(hose);
      setViewStep(2);
      handleChange("hose", hose);
    }
  };

  const onFittingClick = (fitting) => {
    if (fitting) {
      if (fitting.category.name.includes("(2-PC)")) setIsLHFerrule(true);
      setSelectedFitting(fitting);
      if (fitting.category.name.includes("(2-PC)")) {
        setViewStep(3.1);
      } else {
        setIsLHFerrule(false);
        setViewStep(4);
      }
      handleChange("fitting1", fitting);
    }
  };

  const onFerruleClick = (ferrule) => {
    if (ferrule) {
      setSelectedFerrule(ferrule);
      setViewStep(4);
      handleChange("ferrule1", ferrule);
    }
  };

  const onFittingClick2 = (fitting) => {
    if (fitting) {
      if (fitting.category.name.includes("(2-PC)")) setIsRHFerrule(true);
      setSelectedFitting2(fitting);
      if (fitting.category.name.includes("(2-PC)")) {
        setViewStep(4.1);
      } else {
        setIsRHFerrule(false);
        setViewStep(5);
      }
      handleChange("fitting2", fitting);
    }
  };

  const onFerruleClick2 = (ferrule) => {
    if (ferrule) {
      setViewStep(5);
      handleChange("ferrule2", ferrule);
    }
  };

  const onDuplicateFirstFitting = () => {
    onFittingClick2(selectedFitting);
    setTimeout(function () {
      onFerruleClick2(selectedFerrule);
    }, 1000);
    setTimeout(function () {
      handleChange("ferrule2", selectedFerrule);
    }, 2000);
  };

  useEffect(() => {
    const countryCode = localStorage.getItem("userCountryCode");
    setUserCountryCode(countryCode);
    getCauseTypes();
    getDamageTypes();
  }, []);

  useEffect(() => {
    if (viewStep >= 1) {
      const container = document.getElementById("scroll-container");
      container.scrollTo({ top: 0, behavior: "smooth" });
    }
    if (props.hoseMakerIndex === null) {
      setStep(viewStep);
    }
  }, [viewStep]);

  useEffect(() => {
    if (
      selectedFitting?.category?.name?.includes("(ANGLED)") &&
      selectedFitting2?.category?.name?.includes("(ANGLED)")
    ) {
      setHasOrientation(true);
    } else {
      setHasOrientation(false);
    }
  }, [selectedFitting, selectedFitting2]);

  const getHosePositions = async () => {
    const hosePositions = await apiGetHosePositions(props.assetType);
    if (hosePositions) {
      setLocationList(hosePositions.positions);
    }
  };

  const getProduct = async (type, productName) => {
    const product = await apiGetProduct(productName);
    if (type === "hose") {
      setSelectedHose(product);
    }
    if (type === "fitting1") {
      setSelectedFitting(product);
    }
    if (type === "fitting2") {
      setSelectedFitting2(product);
    }
  };

  const getProductDetails = async (productName) => {
    const product = await apiGetProduct(productName);

    return product;
  };

  const getPositionImage = (id) => {
    if (locationList.length > 0) {
      let position = {};
      position = locationList.find((location) => location.id === id);
      if (position) return position.url ? position.url : props.assetImage;
    }
    return props.assetImage;
  };

  useEffect(() => {
    if (props.assetType) {
      getHosePositions();
    }
  }, [props.assetType]);

  useEffect(() => {
    if (props.hoseMakerData) {
      const selectedData = props.hoseMakerData;

      props.hoseMakerData.lfActualCrimpSizeString =
        selectedData.bomAssembly?.lfActualCrimpSize?.toString();
      props.hoseMakerData.rfActualCrimpSizeString =
        selectedData.bomAssembly?.rfActualCrimpSize?.toString();

      selectedData.lfActualCrimpSize =
        selectedData.bomAssembly?.lfActualCrimpSize;
      selectedData.rfActualCrimpSize =
        selectedData.bomAssembly?.rfActualCrimpSize;
      selectedData.isMarkedPushedOnCorrectly =
        selectedData.bomAssembly?.isMarkedPushedOnCorrectly;
      selectedData.damageType = selectedData.bomAssembly?.damageType;
      selectedData.causeType = selectedData.bomAssembly?.causeType;
      selectedData.hoseReplacementType =
        selectedData.bomAssembly?.hoseReplacementType;

      const goal = Math.floor(selectedData.oal);
      const oalDecimal = selectedData.oal - goal;

      selectedData.oal = goal;
      selectedData.oalDecimal = oalDecimal;
      selectedData.oalString = selectedData.oal?.toString();

      const otherItems = selectedData.others?.map((data) => {
        data.stockCode = data.barcode;
        data.stockDescription = data.name;
        data.stockImage1 = data.image128;
        return data;
      });

      props.hoseMakerData.hoseIdTagging?.map((item, i) => {
        item.hoseIdSequence = i;
        selectedData.hoseName = item.hoseName;
        selectedData.hoseOemNumber = item.hoseOemNumber;
        selectedData.isHoseCleaned = item.isHoseCleaned;
        selectedData.isVisuallyInspected = item.isVisuallyInspected;
        selectedData.isDesignStandard = item.isDesignStandard;
      });

      setOtherItemsList(otherItems);

      if (props.hoseMakerIndex !== null) {
        getProduct("hose", selectedData.step1_Barcode);
        getProduct("fitting1", selectedData.step2_Barcode);
        getProduct("fitting2", selectedData.step4_Barcode);
        if (selectedData.lfActualCrimpSize) {
          setStep(7);
        } else {
          setStep(6);
        }
        if (isAssemble) {
          setStep(7);
          setViewStep(7);
        } else {
          setViewStep(1);
        }
        setFormData(props.hoseMakerData);
      } else {
        setTimeout(function () {
          setFormData(hoseMakerTempData);
        }, 500);
      }
    } else {
      setFormData({
        step1_Hose: 0,
        step1_Barcode: "",
        step1_Name: "",
        step2_LHF: 0,
        step2_Barcode: "",
        step2_Name: "",
        step3_LFerrule: 0,
        step3_Barcode: "",
        step3_Name: "",
        step4_RHF: 0,
        step4_Barcode: "",
        step4_Name: "",
        step5_RFerrule: 0,
        step5_Barcode: "",
        step5_Name: "",
        quantity: 1,
        orientation: null,
        orientationString: "",
        oal: null,
        oalDecimal: null,
        oalString: "",
        positionId: 0,
        others: [],
        lfActualCrimpSize: 0,
        rfActualCrimpSize: 0,
        isMarkedPushedOnCorrectly: false,
        bomAssembly: {
          lfActualCrimpSize: 0,
          rfActualCrimpSize: 0,
          isMarkedPushedOnCorrectly: false,
          image1: "",
          image2: "",
          image3: "",
          image4: "",
          createdDateTime: null,
          lastUpdatedDateTime: null,
        },
      });
    }
  }, [props.hoseMakerData]);

  useEffect(() => {
    const currentIndex =
      data.hoseMaker.length === 0
        ? 0
        : props.hoseMakerIndex
        ? props.hoseMakerIndex
        : props.hoseMakerIndex === 0
        ? 0
        : data.hoseMaker.length;
  }, []);

  return (
    <React.Fragment>
      <MetaData title="Job Management" />
      <div className="hose-maker">
        {viewTestingCertificate && (
          <HoseTrainingCertificate
            isLoading={isLoading}
            jobId={data.id}
            currentStatus={currentStatus}
            hoseMakerSeqId={data.hoseMaker[hoseMakerIndex].seqId}
            hoseTagData={hoseTagData}
            hoseTagSeqId={hoseTagData.hoseIdSequence}
            setViewTestingCertificate={setViewTestingCertificate}
            getTestCertificate={getTestCertificate}
            deleteTestCertificate={deleteTestCertificate}
          />
        )}
        <Modal size="mini" open={deleteImagePopupOpen}>
          <div style={{ position: "absolute", bottom: 100, left: 150 }}>
            {isLoading ? (
              <Loader
                active
                className="workaround"
                size="large"
                inline="centered"
              />
            ) : null}
          </div>
          <Modal.Header>Confirmation</Modal.Header>
          <Modal.Content
            style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
          >
            <p>
              Are you sure <br /> you want to delete this image?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setDeleteImagePopupOpen(false)}>NO</Button>
            <Button
              style={{
                backgroundColor: "rgb(102, 204, 35)",
                color: "#fff",
              }}
              onClick={() => onDeleteHoseMakerImage()}
            >
              YES
            </Button>
          </Modal.Actions>
        </Modal>
        <Grid>
          <Grid.Column width={3}>
            <h3>
              <Icon name="wrench" /> Hose Maker
            </h3>
          </Grid.Column>
          <Grid.Column
            width={10}
            className="job-list-section"
            style={{ paddingRight: 0 }}
          >
            <div class="arrow-steps clearfix">
              <div
                className={
                  viewStep === 0 || viewStep === 1 ? "step current" : "step"
                }
                onClick={() => setViewStep(1)}
              >
                <span>
                  <a>Select Hose</a>
                </span>
              </div>
              {step > 1 ? (
                <div
                  className={viewStep === 2 ? "step current" : "step"}
                  style={{ width: 130, paddingRight: 10, textAlign: "right" }}
                  onClick={() => setViewStep(2)}
                >
                  <span>
                    <a>Set hose length</a>
                  </span>
                </div>
              ) : null}
              {step > 2 ? (
                <div
                  className={viewStep === 3 ? "step current" : "step"}
                  style={{ width: 130, paddingRight: 10, textAlign: "right" }}
                  onClick={() => setViewStep(3)}
                >
                  <span>
                    <a>Select first fitting</a>
                  </span>
                </div>
              ) : null}
              {step >= 3.1 && isLHFerrule ? (
                <div
                  className={viewStep === 3.1 ? "step current" : "step"}
                  style={{ width: 110, paddingRight: 10, textAlign: "right" }}
                  onClick={() => setViewStep(3.1)}
                >
                  <span>
                    <a>Select ferrule</a>
                  </span>
                </div>
              ) : null}
              {step > 3.1 ? (
                <div
                  className={viewStep === 4 ? "step current" : "step"}
                  style={{ width: 145, paddingRight: 5, textAlign: "right" }}
                  onClick={() => setViewStep(4)}
                >
                  <span>
                    <a>Select second fitting</a>
                  </span>
                </div>
              ) : null}
              {step >= 4.1 && isRHFerrule ? (
                <div
                  className={viewStep === 4.1 ? "step current" : "step"}
                  style={{ width: 110, paddingRight: 10, textAlign: "right" }}
                  onClick={() => setViewStep(4.1)}
                >
                  <span>
                    <a>Select ferrule</a>
                  </span>
                </div>
              ) : null}
              {step > 4.1 ? (
                <div
                  className={viewStep === 5 ? "step current" : "step"}
                  style={{ width: 200, paddingRight: 5, textAlign: "right" }}
                  onClick={() => setViewStep(5)}
                >
                  <span>
                    <a>
                      {" "}
                      {hasOrientation
                        ? "Set orientation and accessories"
                        : "Select hose assembly accessories"}
                    </a>
                  </span>
                </div>
              ) : null}
              {step > 5 ? (
                <div
                  className={viewStep === 6 ? "step current" : "step"}
                  style={{ width: 115, paddingRight: 10, textAlign: "right" }}
                  onClick={() => setViewStep(6)}
                >
                  <span>
                    <a>Select position</a>
                  </span>
                </div>
              ) : null}
              {step > 6 ? (
                <div
                  className={viewStep === 7 ? "step current" : "step"}
                  style={{
                    width: isQuoting ? 110 : 125,
                    paddingRight: 10,
                    textAlign: "right",
                  }}
                  onClick={() => setViewStep(7)}
                >
                  <span>
                    <a>{isQuoting ? "BOM Details" : "Assemble BOM"}</a>
                  </span>
                </div>
              ) : null}
            </div>
          </Grid.Column>
          <Grid.Column width={3} style={{ paddingRight: 0 }}>
            {step === 7 ? (
              <div className="hose-actions">
                <Button size="small" onClick={() => props.onCancelHoseMaker()}>
                  {currentStatus > 2 ? "Close" : "Cancel"}
                </Button>
                <Button
                  className="active-status"
                  size="small"
                  onClick={() => onConfirmBOM()}
                  style={{ display: currentStatus > 2 ? "none" : "visible" }}
                >
                  Confirm
                </Button>
              </div>
            ) : (
              <div className="hose-actions">
                <Button size="small" onClick={() => props.onCancelHoseMaker()}>
                  {currentStatus > 2 ? "Close" : "Cancel"}
                </Button>
              </div>
            )}
          </Grid.Column>
        </Grid>
        <Grid columns={3} divided>
          <Grid.Column
            computer={3}
            tablet={6}
            mobile={16}
            className="job-list-section"
          >
            <Grid disabled={currentStatus > 2}>
              <Grid.Column width={16}>
                {isQuoting ? (
                  <Header
                    as="h4"
                    style={{
                      textDecoration: "underline",
                      textUnderlineOffset: 5,
                    }}
                  >
                    {data && data.quoteDetails?.quoteNumber
                      ? `Quote #${data.quoteDetails?.quoteNumber}`
                      : `New Quote Request`}
                  </Header>
                ) : (
                  <Header
                    as="h4"
                    style={{
                      textDecoration: "underline",
                      textUnderlineOffset: 5,
                    }}
                  >
                    {data && data.jobDetails?.jobNumber
                      ? `Job #${data.jobDetails?.jobNumber}`
                      : `New Job Request`}
                  </Header>
                )}
              </Grid.Column>
              <Grid.Column width={2} style={{ paddingRight: 0 }}>
                <Image
                  width={18}
                  src={rightArrow}
                  wrapped
                  style={{ marginTop: -12.5 }}
                />
              </Grid.Column>
              <Grid.Column width={14} style={{ paddingLeft: 10 }}>
                <Header as="h5">Customer Details</Header>
              </Grid.Column>
              <Grid.Column width={16} style={{ marginTop: -20, paddingTop: 0 }}>
                <div style={{ marginTop: 10 }}>
                  <span
                    style={{ fontSize: 14 }}
                  >{`Company: \xa0 ${data.customerDetails?.companyName}`}</span>
                  <br />
                  <span
                    style={{ fontSize: 14 }}
                  >{`Contact: \xa0 ${data.customerDetails?.contactName}`}</span>
                  <br />
                  <span
                    style={{ fontSize: 14 }}
                  >{`Location: \xa0 ${data.customerDetails?.customerLocation}`}</span>
                  <br />
                  <span
                    style={{ fontSize: 14 }}
                  >{`Status: \xa0 Work in Progress`}</span>
                </div>
              </Grid.Column>
              <Grid.Column
                width={2}
                style={{ marginTop: 10, paddingTop: 0, paddingRight: 0 }}
              >
                <Image
                  width={18}
                  src={rightArrow}
                  wrapped
                  style={{ marginTop: -12.5 }}
                />
              </Grid.Column>
              <Grid.Column
                width={14}
                style={{
                  marginTop: 10,
                  paddingTop: 0,
                  paddingRight: 0,
                  paddingLeft: 10,
                }}
              >
                <Header as="h5">Asset Details</Header>
              </Grid.Column>
              <Grid.Column width={16} style={{ marginTop: -20, paddingTop: 0 }}>
                <div style={{ marginTop: 10 }}>
                  <span
                    style={{ fontSize: 14 }}
                  >{`Asset ID: \xa0 ${data.assetDetails?.assetSerialNumber}`}</span>
                  <br />
                  <span
                    style={{ fontSize: 14 }}
                  >{`Asset Make: \xa0 ${data.assetDetails?.makeName}`}</span>
                  <br />
                  <span
                    style={{ fontSize: 14 }}
                  >{`Asset Equipment Type: \xa0 ${data.assetDetails?.equipmentTypeName}`}</span>
                  <br />
                  <span
                    style={{ fontSize: 14 }}
                  >{`Asset Model: \xa0 ${data.assetDetails?.model}`}</span>
                  <br />
                  <span
                    style={{ fontSize: 14 }}
                  >{`Asset Hours/Mileage: \xa0 ${data.assetDetails?.assetPropValue}${data.assetDetails?.assetPropUnit}`}</span>
                </div>
              </Grid.Column>
              <Grid.Column
                width={2}
                style={{ marginTop: 10, paddingTop: 0, paddingRight: 0 }}
              >
                <Image
                  width={18}
                  src={rightArrow}
                  wrapped
                  style={{ marginTop: -12.5 }}
                />
              </Grid.Column>
              <Grid.Column
                width={14}
                style={{
                  marginTop: 10,
                  paddingTop: 0,
                  paddingRight: 0,
                  paddingLeft: 10,
                }}
              >
                {isQuoting ? (
                  <Header as="h5">Quote Details</Header>
                ) : (
                  <Header as="h5">Job Details</Header>
                )}
              </Grid.Column>
              <Grid.Column width={16} style={{ marginTop: -20, paddingTop: 0 }}>
                <div style={{ marginTop: 10 }}>
                  <span style={{ fontSize: 14 }}>{`${
                    isQuoting ? "Quote" : "Job"
                  } Details: \xa0 ${
                    data.jobDetails?.jobDesc ? data.jobDetails?.jobDesc : ""
                  }`}</span>
                  <br />
                  <span style={{ fontSize: 14 }}>{`Travel: \xa0 ${
                    data.jobDetails?.distanceTravelled
                      ? data.jobDetails?.distanceTravelled
                      : 0
                  }${userCountryCode === "US" ? "mi" : "kms"}`}</span>
                  <br />
                  <span style={{ fontSize: 14 }}>{`Labour: \xa0 ${
                    data.jobDetails?.hoursLabour
                      ? data.jobDetails?.hoursLabour
                      : 0
                  }hrs`}</span>
                </div>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            computer={10}
            tablet={10}
            mobile={16}
            style={{ paddingRight: 0, paddingTop: 0 }}
          >
            <Grid style={{ marginTop: 0 }}>
              <div className="hose-maker-container">
                <div className="hose-maker-scroll" id="scroll-container">
                  {viewStep == 1 ? (
                    <div disabled={currentStatus > 2}>
                      <Grid.Column width={16}>
                        <div id="step1Id">
                          <Header
                            as="h4"
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: 5,
                            }}
                          >
                            1. Select hose type and size
                          </Header>
                        </div>
                      </Grid.Column>
                      <Grid.Column width={16} style={{ padding: "0px 20px" }}>
                        <SearchComponent
                          isHoseMaker={true}
                          onProductClick={onHoseClick}
                          module={"Hydraulic Hose"}
                        />
                      </Grid.Column>
                    </div>
                  ) : null}
                  {viewStep === 2 ? (
                    <div disabled={currentStatus > 2}>
                      <Grid.Column width={16}>
                        <div id="step3Id">
                          <Header
                            as="h4"
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: 5,
                            }}
                          >
                            2. Set hose length
                          </Header>
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        width={16}
                        style={{
                          padding: 20,
                          paddingTop: 50,
                          minHeight: 550,
                        }}
                      >
                        <Grid>
                          <Grid.Column width={5} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginLeft: "auto",
                                borderColor: "rgb(102, 204, 35)",
                                height: 120,
                                width: 130,
                              }}
                            >
                              {selectedFitting && (
                                <Image width="100%" height="100%" />
                              )}
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={6}
                            style={{ padding: 0, zIndex: 1001 }}
                          >
                            <div
                              style={{
                                marginTop: 22,
                                borderTop: "17px solid #000",
                                borderBottom: "17px solid #000",
                                paddingTop: 8,
                                paddingBottom: 8,
                                textAlign: "center",
                              }}
                            >
                              <span style={{ fontSize: 12 }}>
                                {selectedHose.stockDescription}
                              </span>
                            </div>
                          </Grid.Column>
                          <Grid.Column width={5} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginRight: "auto",
                                height: 120,
                                width: 130,
                              }}
                            >
                              {selectedFitting2 && (
                                <Image width="100%" height="100%" />
                              )}
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{ padding: 0, marginTop: 5 }}
                          >
                            <div
                              id="step4Id"
                              style={{ width: 500, margin: "auto" }}
                            >
                              <Header
                                as="h4"
                                style={{
                                  textUnderlineOffset: 5,
                                  textAlign: "center",
                                }}
                              >
                                Enter Cut Length
                              </Header>
                            </div>
                            <div
                              style={{
                                width: 250,
                                margin: "auto",
                                marginTop: 15,
                              }}
                            >
                              {/* <Button
                                className="active-status"
                                size="small"
                                style={{
                                  width: "100%",
                                  backgroundColor: "rgb(102, 204, 35)",
                                  color: "#fff",
                                  marginLeft: "auto",
                                }}
                              >
                                How to measure OAL?
                              </Button> */}
                            </div>
                            {userCountryCode === "US" ? (
                              <div
                                style={{
                                  width: 250,
                                  margin: "auto",
                                  marginTop: 0,
                                  paddingRight: 10,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Input
                                  className={formData.oal < 1 ? "error" : ""}
                                  type="number"
                                  label={
                                    <Dropdown
                                      defaultValue={0}
                                      value={formData?.oalDecimal}
                                      options={options}
                                      onChange={(e, data) =>
                                        handleChange("oalDecimal", data.value)
                                      }
                                      style={{
                                        backgroundColor: "#fff",
                                        border: "1px solid rgba(34,36,38,.15)",
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                      }}
                                    />
                                  }
                                  style={{ width: "50%", marginLeft: -5 }}
                                  min={0}
                                  labelPosition="right"
                                  input={{ style: { textAlign: "center" } }}
                                  value={formData.oalString}
                                  onChange={(e, data) =>
                                    handleChange("oal", data.value)
                                  }
                                  onKeyPress={handleKeyPressOAL}
                                />
                                <Label
                                  basic
                                  style={{
                                    height: 43.83,
                                    paddingTop: 13,
                                    fontSize: 16,
                                    marginLeft: 68,
                                  }}
                                >
                                  {userCountryCode === "US" ? "inches" : "mm"}
                                </Label>
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: 250,
                                  margin: "auto",
                                  marginTop: 0,
                                  paddingRight: 10,
                                }}
                              >
                                <Input
                                  className={formData.oal < 1 ? "error" : ""}
                                  type="number"
                                  label={{
                                    basic: true,
                                    content:
                                      userCountryCode === "US"
                                        ? "inches"
                                        : "mm",
                                  }}
                                  min={0}
                                  labelPosition="right"
                                  style={{ width: "82%" }}
                                  defaultValue={formData.oalString}
                                  onChange={(e, data) =>
                                    handleChange("oal", data.value)
                                  }
                                  onKeyPress={handleKeyPressOAL}
                                  input={{ style: { textAlign: "center" } }}
                                />
                              </div>
                            )}
                            <div
                              style={{
                                width: 250,
                                margin: "auto",
                                marginTop: 25,
                              }}
                            >
                              <Button
                                className="active-status"
                                size="small"
                                style={{
                                  width: "100%",
                                  backgroundColor: "rgb(102, 204, 35)",
                                  color: "#fff",
                                  marginLeft: "auto",
                                }}
                                onClick={() => {
                                  if (onSetOAL()) {
                                    setViewStep(3);
                                  }
                                }}
                              >
                                Next
                              </Button>
                            </div>
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </div>
                  ) : null}
                  {viewStep == 3 ? (
                    <div disabled={currentStatus > 2}>
                      <Grid.Column width={16}>
                        <div id="step2Id">
                          <Header
                            as="h4"
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: 5,
                            }}
                          >
                            3. Select hose fitting
                          </Header>
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        width={16}
                        style={{
                          padding: 20,
                          paddingTop: 50,
                          minHeight: 550,
                        }}
                      >
                        <Grid>
                          <Grid.Column width={5} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginLeft: "auto",
                                borderStyle: "dashed",
                                borderColor: "rgb(102, 204, 35)",
                                height: 120,
                                width: 130,
                              }}
                            >
                              {selectedFitting &&
                              selectedFitting.stockImage1 ? (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={`data:image/jpeg;base64,${selectedFitting.stockImage1}`}
                                />
                              ) : null}
                            </div>
                          </Grid.Column>
                          <Grid.Column width={6} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginTop: 22,
                                borderTop: "17px solid #000",
                                borderBottom: "17px solid #000",
                                paddingTop: 8,
                                paddingBottom: 8,
                                textAlign: "center",
                              }}
                            >
                              <span style={{ fontSize: 12 }}>
                                {selectedHose.stockDescription}
                              </span>
                            </div>
                          </Grid.Column>
                          <Grid.Column width={5}></Grid.Column>
                          <Grid.Column width={16} style={{ zIndex: 1002 }}>
                            <div
                              className="job-status"
                              style={{
                                paddingRight: "0px !important",
                                width: "100%",
                                margin: "auto",
                              }}
                            >
                              <Button
                                className={
                                  status === "1" &&
                                  searchTerm === "Straight" &&
                                  "active-status"
                                }
                                size="medium"
                                onClick={() => {
                                  setStatus("1");
                                  setSearchTerm("Straight");
                                }}
                              >
                                Straight
                              </Button>
                              <Button
                                className={
                                  status === "2" &&
                                  searchTerm === "90° swept" &&
                                  "active-status"
                                }
                                size="medium"
                                onClick={() => {
                                  setStatus("2");
                                  setSearchTerm("90° swept");
                                }}
                              >
                                90° swept
                              </Button>
                              <Button
                                className={
                                  status === "3" &&
                                  searchTerm === "90° compact" &&
                                  "active-status"
                                }
                                size="medium"
                                onClick={() => {
                                  setStatus("3");
                                  setSearchTerm("90° compact");
                                }}
                              >
                                90° compact
                              </Button>
                              <Button
                                className={
                                  status === "4" &&
                                  searchTerm === "45° swept" &&
                                  "active-status"
                                }
                                size="medium"
                                onClick={() => {
                                  setStatus("4");
                                  setSearchTerm("45° swept");
                                }}
                              >
                                45° swept
                              </Button>
                              <Button
                                className={
                                  status === "5" &&
                                  searchTerm === "45° compact" &&
                                  "active-status"
                                }
                                size="medium"
                                onClick={() => {
                                  setStatus("5");
                                  setSearchTerm("45° compact");
                                }}
                              >
                                45° compact
                              </Button>
                              {/* <Button
                                className={status === "6" && "active-status"}
                                size="medium"
                                onClick={() => setStatus("6")}
                              >
                                Other
                              </Button> */}
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{ padding: "0px 20px" }}
                          >
                            <SearchComponent
                              isHoseMaker={true}
                              onProductClick={onFittingClick}
                              module={"Hydraulic Fittings"}
                              searchTerm={searchTerm}
                              marginTop={-260}
                            />
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </div>
                  ) : null}
                  {viewStep == 3.1 ? (
                    <div disabled={currentStatus > 2}>
                      <Grid.Column width={16}>
                        <div id="step2Id">
                          <Header
                            as="h4"
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: 5,
                            }}
                          >
                            3.1 Select ferrule
                          </Header>
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        width={16}
                        style={{
                          padding: 20,
                          paddingTop: 50,
                          minHeight: 550,
                        }}
                      >
                        <Grid>
                          <Grid.Column width={5} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginLeft: "auto",
                                borderStyle: "dashed",
                                borderColor: "rgb(102, 204, 35)",
                                height: 120,
                                width: 130,
                              }}
                            >
                              {selectedFitting &&
                              selectedFitting.stockImage1 ? (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={`data:image/jpeg;base64,${selectedFitting.stockImage1}`}
                                />
                              ) : (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={noImageAvailable}
                                />
                              )}
                            </div>
                          </Grid.Column>
                          <Grid.Column width={6} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginTop: 22,
                                borderTop: "17px solid #000",
                                borderBottom: "17px solid #000",
                                paddingTop: 8,
                                paddingBottom: 8,
                                textAlign: "center",
                              }}
                            >
                              <span style={{ fontSize: 12 }}>
                                {selectedHose.stockDescription}
                              </span>
                            </div>
                          </Grid.Column>
                          <Grid.Column width={5}></Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{ padding: "0px 20px" }}
                          >
                            <SearchComponent
                              isHoseMaker={true}
                              onProductClick={onFerruleClick}
                              module={"Hydraulic Ferrules"}
                              marginTop={-260}
                            />
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </div>
                  ) : null}
                  {viewStep === 4 ? (
                    <div disabled={currentStatus > 2}>
                      <Grid.Column width={16}>
                        <div id="step3Id">
                          <Header
                            as="h4"
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: 5,
                            }}
                          >
                            4. Select second fitting
                          </Header>
                          <Button
                            size="small"
                            style={{
                              position: "absolute",
                              right: "20px",
                              top: "-12px",
                            }}
                            onClick={() => onDuplicateFirstFitting()}
                          >
                            Duplicate first fitting
                          </Button>
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        width={16}
                        style={{
                          padding: 20,
                          paddingTop: 34,
                          minHeight: 550,
                        }}
                      >
                        <Grid>
                          <Grid.Column width={5} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginLeft: "auto",
                                borderColor: "rgb(102, 204, 35)",
                                height: 120,
                                width: 130,
                              }}
                            >
                              {selectedFitting &&
                              selectedFitting.stockImage1 ? (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={`data:image/jpeg;base64,${selectedFitting.stockImage1}`}
                                />
                              ) : (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={noImageAvailable}
                                />
                              )}
                            </div>
                          </Grid.Column>
                          <Grid.Column width={6} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginTop: 22,
                                borderTop: "17px solid #000",
                                borderBottom: "17px solid #000",
                                paddingTop: 8,
                                paddingBottom: 8,
                                textAlign: "center",
                              }}
                            >
                              <span style={{ fontSize: 12 }}>
                                {selectedHose.stockDescription}
                              </span>
                            </div>
                          </Grid.Column>
                          <Grid.Column width={5} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginRight: "auto",
                                borderStyle: "dashed",
                                borderColor: "rgb(102, 204, 35)",
                                height: 120,
                                width: 130,
                              }}
                            >
                              {selectedFitting2 &&
                              selectedFitting2.stockImage1 ? (
                                <Image
                                  style={{ transform: "scaleX(-1)" }}
                                  width="100%"
                                  height="100%"
                                  src={`data:image/jpeg;base64,${selectedFitting2.stockImage1}`}
                                />
                              ) : null}
                            </div>
                          </Grid.Column>
                          <Grid.Column width={16} style={{ zIndex: 1002 }}>
                            <div
                              className="job-status"
                              style={{
                                paddingRight: "0px !important",
                                width: "100%",
                                margin: "auto",
                              }}
                            >
                              <Button
                                className={
                                  status === "11" &&
                                  searchTerm === "Straight" &&
                                  "active-status"
                                }
                                size="medium"
                                onClick={() => {
                                  setStatus("11");
                                  setSearchTerm("Straight");
                                }}
                              >
                                Straight
                              </Button>
                              <Button
                                className={
                                  status === "22" &&
                                  searchTerm === "90° swept" &&
                                  "active-status"
                                }
                                size="medium"
                                onClick={() => {
                                  setStatus("22");
                                  setSearchTerm("90° swept");
                                }}
                              >
                                90° swept
                              </Button>
                              <Button
                                className={
                                  status === "33" &&
                                  searchTerm === "90° compact" &&
                                  "active-status"
                                }
                                size="medium"
                                onClick={() => {
                                  setStatus("33");
                                  setSearchTerm("90° compact");
                                }}
                              >
                                90° compact
                              </Button>
                              <Button
                                className={
                                  status === "44" &&
                                  searchTerm === "45° swept" &&
                                  "active-status"
                                }
                                size="medium"
                                onClick={() => {
                                  setStatus("44");
                                  setSearchTerm("45° swept");
                                }}
                              >
                                45° swept
                              </Button>
                              <Button
                                className={
                                  status === "55" &&
                                  searchTerm === "45° compact" &&
                                  "active-status"
                                }
                                size="medium"
                                onClick={() => {
                                  setStatus("55");
                                  setSearchTerm("45° compact");
                                }}
                              >
                                45° compact
                              </Button>
                              {/* <Button
                                className={status === "66" && "active-status"}
                                size="medium"
                                onClick={() => setStatus("66")}
                              >
                                Other
                              </Button> */}
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{ padding: "0px 20px" }}
                          >
                            <SearchComponent
                              isHoseMaker={true}
                              onProductClick={onFittingClick2}
                              module={"Hydraulic Fittings"}
                              searchTerm={searchTerm}
                              marginTop={-260}
                            />
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </div>
                  ) : null}
                  {viewStep == 4.1 ? (
                    <div disabled={currentStatus > 2}>
                      <Grid.Column width={16}>
                        <div id="step2Id">
                          <Header
                            as="h4"
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: 5,
                            }}
                          >
                            4.1 Select ferrule
                          </Header>
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        width={16}
                        style={{
                          padding: 20,
                          paddingTop: 50,
                          minHeight: 550,
                        }}
                      >
                        <Grid>
                          <Grid.Column width={5} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginLeft: "auto",
                                borderStyle: "dashed",
                                borderColor: "rgb(102, 204, 35)",
                                height: 120,
                                width: 130,
                              }}
                            >
                              {selectedFitting &&
                              selectedFitting.stockImage1 ? (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={`data:image/jpeg;base64,${selectedFitting.stockImage1}`}
                                />
                              ) : (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={noImageAvailable}
                                />
                              )}
                            </div>
                          </Grid.Column>
                          <Grid.Column width={6} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginTop: 22,
                                borderTop: "17px solid #000",
                                borderBottom: "17px solid #000",
                                paddingTop: 8,
                                paddingBottom: 8,
                                textAlign: "center",
                              }}
                            >
                              <span style={{ fontSize: 12 }}>
                                {selectedHose.stockDescription}
                              </span>
                            </div>
                          </Grid.Column>
                          <Grid.Column width={5}></Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{ padding: "0px 20px" }}
                          >
                            <SearchComponent
                              isHoseMaker={true}
                              onProductClick={onFerruleClick2}
                              module={"Hydraulic Ferrules"}
                              marginTop={-260}
                            />
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </div>
                  ) : null}
                  {viewStep === 5 ? (
                    <div disabled={currentStatus > 2}>
                      <Grid.Column width={16}>
                        <div id="step3Id">
                          <Header
                            as="h4"
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: 5,
                            }}
                          >
                            {hasOrientation
                              ? "5. Set orientation and accessories"
                              : "5. Select hose assembly accessories"}
                          </Header>
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        width={16}
                        style={{
                          padding: 20,
                          paddingTop: 50,
                          minHeight: 550,
                        }}
                      >
                        <Grid>
                          <Grid.Column
                            width={16}
                            style={{ padding: 0, marginTop: 5 }}
                          >
                            {hasOrientation && (
                              <div>
                                <div
                                  style={{
                                    marginTop: 25,
                                  }}
                                >
                                  <Header
                                    as="h4"
                                    style={{
                                      textUnderlineOffset: 5,
                                      textAlign: "center",
                                    }}
                                  >
                                    Enter Orientation:
                                  </Header>
                                </div>
                                <div
                                  style={{
                                    width: 250,
                                    margin: "auto",
                                    marginTop: 5,
                                    paddingRight: 10,
                                  }}
                                >
                                  <Input
                                    className={
                                      formData.orientation < 1 ? "error" : ""
                                    }
                                    type="number"
                                    min={0}
                                    label={{
                                      basic: true,
                                      content: "\xa0 ° \xa0",
                                    }}
                                    labelPosition="right"
                                    style={{ width: "95%", textAlign: "right" }}
                                    value={formData.orientationString}
                                    onChange={(e, data) =>
                                      handleChange("orientation", data.value)
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            <div
                              style={{
                                width: 250,
                                margin: "auto",
                                marginTop: 25,
                              }}
                            >
                              <Button
                                className="active-status"
                                size="small"
                                style={{
                                  width: "100%",
                                  backgroundColor: "rgb(102, 204, 35)",
                                  color: "#fff",
                                  marginLeft: "auto",
                                }}
                                onClick={() => {
                                  if (onSetOrientation()) {
                                    setViewStep(6);
                                  }
                                }}
                              >
                                Next
                              </Button>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{
                              padding: "0px 17px",
                              marginBottom: 15,
                            }}
                          >
                            <div
                              style={{
                                marginTop: 25,
                                marginBottom: 10,
                              }}
                            >
                              <Header
                                as="h4"
                                style={{
                                  textUnderlineOffset: 5,
                                  textAlign: "center",
                                }}
                              >
                                Other items:
                              </Header>
                            </div>
                            {otherItemsList.length > 0 ? (
                              <div>
                                {otherItemsList.map((data, index) => (
                                  <div key={index}>
                                    <Card
                                      style={{
                                        width: "100%",
                                      }}
                                      fluid
                                      color="green"
                                    >
                                      <Card.Content>
                                        <Grid>
                                          <Grid.Column width={12}>
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: 14,
                                              }}
                                            >
                                              {`${data.barcode} - ${data.name}`}
                                            </span>
                                            <Grid>
                                              <Grid.Column width={5}>
                                                <div
                                                  style={{
                                                    width: 75,
                                                    height: 75,
                                                  }}
                                                >
                                                  <Image
                                                    style={{
                                                      width: 75,
                                                      height: 75,
                                                    }}
                                                    src={
                                                      data.stockImage1
                                                        ? `data:image/jpeg;base64,${data.stockImage1}`
                                                        : productDefaultImage
                                                    }
                                                  />
                                                </div>
                                              </Grid.Column>
                                              <Grid.Column
                                                width={11}
                                                style={{
                                                  paddingTop: 35,
                                                }}
                                              >
                                                {/* <span>
                                              {getPositionName(data.positionId)}
                                            </span> */}
                                              </Grid.Column>
                                            </Grid>
                                          </Grid.Column>
                                          <Grid.Column width={4}>
                                            <Form
                                              style={{
                                                textAlign: "center",
                                              }}
                                            >
                                              <label>Quantity</label>
                                              <Form.Field>
                                                <NumericInputBox
                                                  value={data.quantity}
                                                  name="quantity"
                                                  min={0}
                                                  max={10000}
                                                  maxLength={6}
                                                  precision={0}
                                                  step={1}
                                                  onChangeHandler={(value) =>
                                                    handleBOMQuantityChange(
                                                      index,
                                                      value
                                                    )
                                                  }
                                                  onKeyDownHandler={(e) => {
                                                    if (e.key === "Enter")
                                                      e.preventDefault();
                                                  }}
                                                />
                                              </Form.Field>
                                            </Form>
                                          </Grid.Column>
                                        </Grid>
                                      </Card.Content>
                                    </Card>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div
                                style={{
                                  color: "#AAAAAA",
                                  textAlign: "center",
                                  marginTop: 30,
                                  fontSize: 32,
                                }}
                              >
                                No other items added!
                              </div>
                            )}
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{ padding: "0px 20px" }}
                          >
                            <SearchComponent
                              isHoseMaker={true}
                              onProductClick={addOtherItems}
                            />
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </div>
                  ) : null}
                  {viewStep === 6 ? (
                    <div disabled={currentStatus > 2}>
                      <Grid.Column width={16}>
                        <div id="step3Id">
                          <Header
                            as="h4"
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: 5,
                            }}
                          >
                            6. Select location
                          </Header>
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        width={16}
                        style={{
                          padding: 20,
                          paddingTop: 50,
                          minHeight: 550,
                        }}
                      >
                        <Grid>
                          <Grid.Column width={5} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginLeft: "auto",
                                borderColor: "rgb(102, 204, 35)",
                                height: 120,
                                width: 130,
                              }}
                            >
                              {selectedFitting &&
                              selectedFitting.stockImage1 ? (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={`data:image/jpeg;base64,${selectedFitting.stockImage1}`}
                                />
                              ) : (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={noImageAvailable}
                                />
                              )}
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={6}
                            style={{ padding: 0, zIndex: 1001 }}
                          >
                            <div
                              style={{
                                marginTop: 22,
                                borderTop: "17px solid #000",
                                borderBottom: "17px solid #000",
                                paddingTop: 8,
                                paddingBottom: 8,
                                textAlign: "center",
                              }}
                            >
                              <span style={{ fontSize: 12 }}>
                                {selectedHose.stockDescription}
                              </span>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={5}
                            style={{ padding: 0, zIndex: 1001 }}
                          >
                            <div
                              style={{
                                marginRight: "auto",
                                height: 120,
                                width: 130,
                              }}
                            >
                              {selectedFitting2 &&
                              selectedFitting2.stockImage1 ? (
                                <Image
                                  style={{ transform: "scaleX(-1)" }}
                                  width="100%"
                                  height="100%"
                                  src={`data:image/jpeg;base64,${selectedFitting2.stockImage1}`}
                                />
                              ) : (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={noImageAvailable}
                                />
                              )}
                            </div>
                          </Grid.Column>
                          {getPositionImage(formData.positionId) ? (
                            <Grid.Column
                              width={16}
                              style={{
                                textAlign: "center",
                                marginTop: "-1rem",
                                paddingTop: 0,
                                paddingBottom: 10,
                              }}
                            >
                              <div
                                className="ui image"
                                style={{ height: 200, width: 200 }}
                              >
                                <img
                                  src={getPositionImage(formData.positionId)}
                                  style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                  }}
                                />
                              </div>
                            </Grid.Column>
                          ) : null}
                          <Grid.Column
                            width={16}
                            style={{
                              zIndex: 1001,
                              padding: 0,
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  marginTop: 5,
                                }}
                              >
                                <Header
                                  as="h4"
                                  style={{
                                    textUnderlineOffset: 5,
                                    textAlign: "center",
                                  }}
                                >
                                  Select Location:
                                </Header>
                              </div>
                              <div
                                style={{
                                  width: 320,
                                  margin: "auto",
                                  marginTop: 5,
                                  paddingRight: 10,
                                  marginBottom: 25,
                                }}
                              >
                                {locationList.length > 0 ? (
                                  <Dropdown
                                    className={
                                      formData?.positionId === 0
                                        ? "select-error"
                                        : ""
                                    }
                                    placeholder="Select Location"
                                    fluid
                                    search
                                    selection
                                    options={locationList}
                                    value={formData.positionId}
                                    onChange={(e, data) =>
                                      handleChange(
                                        "positionId",
                                        Number(data.value)
                                      )
                                    }
                                    style={{
                                      width: "105%",
                                    }}
                                  />
                                ) : (
                                  <Message
                                    style={{ marginTop: 50 }}
                                    error
                                    icon="exclamation circle"
                                    header="No positions available"
                                  />
                                )}
                              </div>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={6}
                            style={{ padding: 0, marginTop: 5 }}
                          ></Grid.Column>
                          <Grid.Column
                            width={4}
                            style={{
                              padding: 0,
                              marginTop: 5,
                              marginBottom: 35,
                              paddingLeft: 25,
                              paddingRight: 25,
                            }}
                          >
                            <Form
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <label>Quantity</label>
                              <Form.Field>
                                <NumericInputBox
                                  value={formData.quantity}
                                  name="quantity"
                                  min={1}
                                  max={10000}
                                  maxLength={6}
                                  precision={0}
                                  step={1}
                                  onChangeHandler={(value) =>
                                    handleChange("quantity", Number(value))
                                  }
                                  onKeyDownHandler={(e) => {
                                    if (e.key === "Enter") e.preventDefault();
                                  }}
                                />
                              </Form.Field>
                            </Form>
                          </Grid.Column>
                          <Grid.Column
                            width={6}
                            style={{ padding: 0, marginTop: 5 }}
                          ></Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{ padding: 0, marginTop: 0 }}
                          >
                            <div
                              style={{
                                width: 250,
                                margin: "auto",
                                marginTop: 0,
                              }}
                            >
                              {formData.lfActualCrimpSize &&
                              formData.lfActualCrimpSize > 0 ? (
                                <Button
                                  className="active-status"
                                  size="small"
                                  style={{
                                    width: "100%",
                                    backgroundColor: "rgb(102, 204, 35)",
                                    color: "#fff",
                                    marginLeft: "auto",
                                  }}
                                  onClick={() => {
                                    setViewStep(7);
                                  }}
                                >
                                  Next
                                </Button>
                              ) : (
                                <>
                                  <Button
                                    className="active-status"
                                    size="small"
                                    style={{
                                      width: "100%",
                                      marginLeft: "auto",
                                      marginBottom: 10,
                                    }}
                                    onClick={() => {
                                      setViewStep(7);
                                      onConfirmBOM({ closeForm: true });
                                    }}
                                  >
                                    Assemble NOW!
                                  </Button>
                                  <Button
                                    className="edit-status"
                                    size="small"
                                    style={{
                                      width: "100%",
                                      marginLeft: "auto",
                                    }}
                                    onClick={() => onConfirmBOM()}
                                  >
                                    Assemble Later
                                  </Button>
                                </>
                              )}
                            </div>
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </div>
                  ) : null}
                  {viewStep === 7 ? (
                    <div>
                      <Grid.Column width={16}>
                        <div id="step3Id">
                          <Header
                            as="h4"
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: 5,
                            }}
                          >
                            7. {isQuoting ? "BOM Details" : "Assemble BOM"}
                          </Header>
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        width={16}
                        style={{
                          padding: 20,
                          paddingTop: 12,
                          minHeight: 550,
                        }}
                      >
                        <Grid>
                          <Grid.Column
                            width={16}
                            style={{
                              padding: 20,
                              marginBottom: -20,
                            }}
                          >
                            <div>
                              <Header
                                as="h5"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                Cut Length: {formData.oal}
                                {userCountryCode === "US" ? "inches" : "mm"}
                              </Header>
                            </div>
                          </Grid.Column>
                          <Grid.Column width={5} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginLeft: "auto",
                                borderColor: "rgb(102, 204, 35)",
                                height: 120,
                                width: 130,
                              }}
                            >
                              {selectedFitting &&
                              selectedFitting.stockImage1 ? (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={`data:image/jpeg;base64,${selectedFitting.stockImage1}`}
                                />
                              ) : (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={noImageAvailable}
                                />
                              )}
                            </div>
                          </Grid.Column>
                          <Grid.Column width={6} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginTop: 22,
                                borderTop: "17px solid #000",
                                borderBottom: "17px solid #000",
                                paddingTop: 8,
                                paddingBottom: 8,
                                textAlign: "center",
                              }}
                            >
                              <span style={{ fontSize: 12 }}>
                                {formData.step1_Name}
                              </span>
                            </div>
                          </Grid.Column>
                          <Grid.Column width={5} style={{ padding: 0 }}>
                            <div
                              style={{
                                marginRight: "auto",
                                height: 120,
                                width: 130,
                              }}
                            >
                              {selectedFitting2 &&
                              selectedFitting2.stockImage1 ? (
                                <Image
                                  style={{ transform: "scaleX(-1)" }}
                                  width="100%"
                                  height="100%"
                                  src={`data:image/jpeg;base64,${selectedFitting2.stockImage1}`}
                                />
                              ) : (
                                <Image
                                  width="100%"
                                  height="100%"
                                  src={noImageAvailable}
                                />
                              )}
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{ paddingTop: 5, paddingBottom: 0 }}
                          >
                            <div style={{ textAlign: "center", marginTop: 0 }}>
                              <label
                                style={{ fontSize: 20, fontWeight: "bold" }}
                              >
                                HOSE NAME
                              </label>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            disabled={currentStatus > 2}
                            width={16}
                            style={{
                              padding: 0,
                              marginTop: 5,
                              marginBottom: 10,
                              paddingLeft: 25,
                              paddingRight: 25,
                            }}
                          >
                            <Form
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <Form.Field>
                                <Input
                                  style={{ width: "35%" }}
                                  value={formData?.hoseName}
                                  onChange={(e, data) =>
                                    handleChange("hoseName", data.value)
                                  }
                                />
                              </Form.Field>
                            </Form>
                          </Grid.Column>
                          <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                            <div style={{ textAlign: "center", marginTop: 0 }}>
                              <label
                                style={{ fontSize: 20, fontWeight: "bold" }}
                              >
                                ASSEMBLY OEM NUMBER
                              </label>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            disabled={currentStatus > 2}
                            width={16}
                            style={{
                              padding: 0,
                              marginTop: 5,
                              marginBottom: 10,
                              paddingLeft: 25,
                              paddingRight: 25,
                            }}
                          >
                            <Form
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <Form.Field>
                                <Input
                                  style={{ width: "35%" }}
                                  value={formData?.hoseOemNumber}
                                  onChange={(e, data) =>
                                    handleChange("hoseOemNumber", data.value)
                                  }
                                />
                              </Form.Field>
                            </Form>
                          </Grid.Column>
                          {localStorage.getItem("boahubIsRS23") === "true" ? (
                            <>
                              <Grid.Column
                                disabled={currentStatus > 2}
                                width={16}
                                style={{ paddingLeft: 160 }}
                              >
                                <div
                                  style={{ textAlign: "left", marginTop: 15 }}
                                >
                                  <label>
                                    Is it RS23 compliant hose assembly?
                                  </label>
                                  <Checkbox
                                    toggle
                                    style={{
                                      marginLeft: 15,
                                      marginBottom: -7,
                                      position: "absolute",
                                      right: 200,
                                    }}
                                    checked={formData.isRS23Compliant}
                                    onChange={(e, data) =>
                                      handleChange(
                                        "isRS23Compliant",
                                        data.checked
                                      )
                                    }
                                  />
                                </div>
                              </Grid.Column>
                              {formData.isRS23Compliant && !isQuoting && (
                                <Grid.Column
                                  disabled={currentStatus > 2}
                                  width={16}
                                  style={{ paddingLeft: 160 }}
                                >
                                  <div
                                    style={{ textAlign: "left", marginTop: 0 }}
                                  >
                                    <label>Matched hose and fittings?</label>
                                    <Checkbox
                                      toggle
                                      style={{
                                        marginLeft: 15,
                                        marginBottom: -7,
                                        position: "absolute",
                                        right: 200,
                                      }}
                                      checked={formData.isDesignStandard}
                                      onChange={(e, data) =>
                                        handleChange(
                                          "isDesignStandard",
                                          data.checked
                                        )
                                      }
                                    />
                                  </div>
                                </Grid.Column>
                              )}
                              {formData.isRS23Compliant && !isQuoting && (
                                <Grid.Column
                                  disabled={currentStatus > 2}
                                  width={16}
                                  style={{ paddingLeft: 160 }}
                                >
                                  <div
                                    style={{ textAlign: "left", marginTop: 0 }}
                                  >
                                    <label>Hose visually inspected?</label>
                                    <Checkbox
                                      toggle
                                      style={{
                                        marginLeft: 15,
                                        marginBottom: -7,
                                        position: "absolute",
                                        right: 200,
                                      }}
                                      checked={formData.isVisuallyInspected}
                                      onChange={(e, data) =>
                                        handleChange(
                                          "isVisuallyInspected",
                                          data.checked
                                        )
                                      }
                                    />
                                  </div>
                                </Grid.Column>
                              )}
                              {formData.isRS23Compliant && !isQuoting && (
                                <Grid.Column
                                  disabled={currentStatus > 2}
                                  width={16}
                                  style={{ paddingLeft: 160 }}
                                >
                                  <div
                                    style={{ textAlign: "left", marginTop: 0 }}
                                  >
                                    <label>Hose cleaned properly?</label>
                                    <Checkbox
                                      toggle
                                      style={{
                                        marginLeft: 15,
                                        marginBottom: -7,
                                        position: "absolute",
                                        right: 200,
                                      }}
                                      checked={formData.isHoseCleaned}
                                      onChange={(e, data) =>
                                        handleChange(
                                          "isHoseCleaned",
                                          data.checked
                                        )
                                      }
                                    />
                                  </div>
                                </Grid.Column>
                              )}
                              {formData.isRS23Compliant && !isQuoting && (
                                <Grid.Column width={16}>
                                  <div
                                    style={{
                                      textAlign: "center",
                                      marginTop: 10,
                                    }}
                                  >
                                    <label style={{ color: "#A4A4A4" }}>
                                      *If the hose is being stored, ensure
                                      storage instructions detailed in{" "}
                                      <a
                                        href="https://boa-rs23.s3.ap-southeast-2.amazonaws.com/RS23+Hose+Storage+Instruction.pdf"
                                        target="_blank"
                                      >
                                        RS23 point 12.9.1
                                      </a>{" "}
                                      are followed
                                    </label>
                                  </div>
                                </Grid.Column>
                              )}
                            </>
                          ) : null}
                          {!isQuoting ? (
                            <>
                              {" "}
                              <Grid.Column
                                disabled={currentStatus > 2}
                                width={16}
                                style={{ paddingLeft: 160 }}
                              >
                                <div
                                  style={{ textAlign: "left", marginTop: 0 }}
                                >
                                  <label>
                                    Fittings marked and pushed on correctly?
                                  </label>
                                  <Checkbox
                                    toggle
                                    style={{
                                      marginLeft: 15,
                                      marginBottom: -7,
                                      position: "absolute",
                                      right: 200,
                                    }}
                                    checked={formData.isMarkedPushedOnCorrectly}
                                    onChange={(e, data) =>
                                      handleChange(
                                        "isMarkedPushedOnCorrectly",
                                        data.checked
                                      )
                                    }
                                  />
                                </div>
                              </Grid.Column>
                              <Grid.Column width={3}>
                                <div style={{ paddingTop: 6 }}>
                                  <span style={{ fontSize: 12 }}>
                                    Actual Crimp Spec:{" "}
                                  </span>
                                </div>
                              </Grid.Column>
                              <Grid.Column
                                disabled={currentStatus > 2}
                                width={2}
                                style={{
                                  padding: 0,
                                  marginTop: 15,
                                  marginLeft: -25,
                                }}
                              >
                                <div>
                                  <Input
                                    type="number"
                                    min={0}
                                    label={{
                                      basic: true,
                                      content: "mm",
                                    }}
                                    labelPosition="right"
                                    style={{ width: "82%" }}
                                    value={formData.lfActualCrimpSizeString}
                                    onChange={(e, data) =>
                                      handleChange(
                                        "lfActualCrimpSize",
                                        data.value
                                      )
                                    }
                                  />
                                </div>
                              </Grid.Column>
                              <Grid.Column width={4}></Grid.Column>
                              <Grid.Column width={3}>
                                <div style={{ paddingTop: 6 }}>
                                  <span style={{ fontSize: 12 }}>
                                    Actual Crimp Spec:{" "}
                                  </span>
                                </div>
                              </Grid.Column>
                              <Grid.Column
                                disabled={currentStatus > 2}
                                width={2}
                                style={{
                                  padding: 0,
                                  marginTop: 15,
                                  marginLeft: -25,
                                }}
                              >
                                <div>
                                  <Input
                                    type="number"
                                    min={0}
                                    label={{
                                      basic: true,
                                      content: "mm",
                                    }}
                                    labelPosition="right"
                                    style={{ width: "82%" }}
                                    value={formData.rfActualCrimpSizeString}
                                    onChange={(e, data) =>
                                      handleChange(
                                        "rfActualCrimpSize",
                                        data.value
                                      )
                                    }
                                  />
                                </div>
                              </Grid.Column>
                            </>
                          ) : null}
                          {isQuoting ? (
                            <Grid.Column
                              width={16}
                              disabled={currentStatus > 2}
                            >
                              <div
                                style={{ textAlign: "center", marginTop: 0 }}
                              >
                                <label
                                  style={{ fontSize: 20, fontWeight: "bold" }}
                                >
                                  Hose Replacement Details
                                </label>
                              </div>
                              <div
                                style={{
                                  width: "35%",
                                  marginTop: 15,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <div
                                  style={{
                                    marginTop: 11,
                                    textAlign: "right",
                                    position: "absolute",
                                    left: 169,
                                  }}
                                >
                                  <span>Cause Type</span>
                                </div>
                                <Select
                                  placeholder="Cause Type"
                                  style={{ fontSize: 16, width: "100%" }}
                                  options={causeTypes}
                                  value={formData.causeType}
                                  onChange={(e, data) =>
                                    handleChange("causeType", data.value)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  width: "35%",
                                  marginTop: 15,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <div
                                  style={{
                                    marginTop: 11,
                                    textAlign: "right",
                                    position: "absolute",
                                    left: 155,
                                  }}
                                >
                                  <span>Damage Type</span>
                                </div>
                                <Select
                                  placeholder="Damage Type"
                                  style={{ fontSize: 16, width: "100%" }}
                                  options={damageTypes}
                                  value={formData.damageType}
                                  onChange={(e, data) =>
                                    handleChange("damageType", data.value)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  width: "35%",
                                  marginTop: 15,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <div
                                  style={{
                                    marginTop: 11,
                                    textAlign: "right",
                                    position: "absolute",
                                    left: 65,
                                  }}
                                >
                                  <span>Type of Hose Replacement</span>
                                </div>
                                <Select
                                  placeholder="Type of Hose Replacement"
                                  style={{ fontSize: 16, width: "100%" }}
                                  options={replacementList}
                                  value={formData.hoseReplacementType}
                                  onChange={(e, data) =>
                                    handleChange(
                                      "hoseReplacementType",
                                      data.value
                                    )
                                  }
                                />
                              </div>
                            </Grid.Column>
                          ) : (
                            <>
                              <Grid.Column width={16}>
                                <div
                                  style={{ textAlign: "center", marginTop: 0 }}
                                >
                                  <label
                                    style={{ fontSize: 20, fontWeight: "bold" }}
                                  >
                                    HOSE I.D
                                  </label>
                                </div>
                              </Grid.Column>
                              {formData?.hoseIdTagging?.map((data, index) => {
                                return (
                                  <Grid.Column
                                    width={16}
                                    style={{
                                      padding: 0,
                                      marginTop: 5,
                                      marginBottom: 10,
                                      paddingLeft: 25,
                                      paddingRight: 25,
                                    }}
                                  >
                                    <Form
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      {localStorage.getItem("boahubIsRS23") ===
                                        "true" && formData.isRS23Compliant ? (
                                        <div
                                          disabled={
                                            currentStatus > 2 &&
                                            !data?.testCertFilePath
                                          }
                                          style={{
                                            marginTop: 6,
                                            textAlign: "right",
                                            position: "absolute",
                                            right: data?.testCertFilePath
                                              ? 80
                                              : 65,
                                            color: data?.testCertFilePath
                                              ? "#66cc23"
                                              : "#FFAD00",
                                            cursor: "pointer",
                                            overflow: "visible",
                                          }}
                                          onClick={() => {
                                            setHoseTagData(data);
                                            setViewTestingCertificate(true);
                                          }}
                                        >
                                          <img
                                            style={{
                                              width: 40,
                                              marginRight: 5,
                                              marginTop: -3.5,
                                            }}
                                            src={
                                              data?.testCertFilePath
                                                ? certificateIcon
                                                : certificateUploadIcon
                                            }
                                          />
                                          {data?.testCertFilePath
                                            ? "View"
                                            : "Upload"}{" "}
                                          Certificate
                                        </div>
                                      ) : null}
                                      <div disabled={currentStatus > 2}>
                                        <Form.Field>
                                          <Input
                                            style={{ width: "35%" }}
                                            value={data?.hoseId}
                                            onChange={(e, data) =>
                                              handleHoseIDChange(
                                                data.value,
                                                index
                                              )
                                            }
                                            placeholder={"Hose " + (index + 1)}
                                          />
                                        </Form.Field>
                                      </div>
                                    </Form>
                                  </Grid.Column>
                                );
                              })}
                              <Grid.Column
                                width={16}
                                disabled={currentStatus > 2}
                              >
                                <div
                                  style={{ textAlign: "center", marginTop: 0 }}
                                >
                                  <label
                                    style={{ fontSize: 20, fontWeight: "bold" }}
                                  >
                                    Hose Replacement Details
                                  </label>
                                </div>
                                <div
                                  style={{
                                    width: "35%",
                                    marginTop: 15,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginTop: 11,
                                      textAlign: "right",
                                      position: "absolute",
                                      left: 169,
                                    }}
                                  >
                                    <span>Cause Type</span>
                                  </div>
                                  <Select
                                    placeholder="Cause Type"
                                    style={{ fontSize: 16, width: "100%" }}
                                    options={causeTypes}
                                    value={formData.causeType}
                                    onChange={(e, data) =>
                                      handleChange("causeType", data.value)
                                    }
                                  />
                                </div>
                                <div
                                  style={{
                                    width: "35%",
                                    marginTop: 15,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginTop: 11,
                                      textAlign: "right",
                                      position: "absolute",
                                      left: 155,
                                    }}
                                  >
                                    <span>Damage Type</span>
                                  </div>
                                  <Select
                                    placeholder="Damage Type"
                                    style={{ fontSize: 16, width: "100%" }}
                                    options={damageTypes}
                                    value={formData.damageType}
                                    onChange={(e, data) =>
                                      handleChange("damageType", data.value)
                                    }
                                  />
                                </div>
                                <div
                                  style={{
                                    width: "35%",
                                    marginTop: 15,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginTop: 11,
                                      textAlign: "right",
                                      position: "absolute",
                                      left: 65,
                                    }}
                                  >
                                    <span>Type of Hose Replacement</span>
                                  </div>
                                  <Select
                                    placeholder="Type of Hose Replacement"
                                    style={{ fontSize: 16, width: "100%" }}
                                    options={replacementList}
                                    value={formData.hoseReplacementType}
                                    onChange={(e, data) =>
                                      handleChange(
                                        "hoseReplacementType",
                                        data.value
                                      )
                                    }
                                  />
                                </div>
                              </Grid.Column>
                            </>
                          )}
                          <Grid.Column
                            width={16}
                            style={{
                              marginTop: 15,
                            }}
                          >
                            <Grid style={{ padding: 0, margin: 0 }}>
                              {hoseMakerImages &&
                                hoseMakerImages.map((imageData, index) => {
                                  return (
                                    <Grid.Column
                                      width={4}
                                      style={{ padding: 0, margin: 0 }}
                                    >
                                      <div
                                        className="ui image"
                                        style={{
                                          height: 130,
                                          width: 130,
                                          marginTop: 10,
                                        }}
                                      >
                                        <img
                                          src={imageData.imageFilePath}
                                          alt="Image Preview"
                                          className="bomImage"
                                          style={{
                                            marginTop: "auto",
                                            marginBottom: "auto",
                                            cursor: "pointer",
                                          }}
                                        />
                                        {currentStatus < 3 && (
                                          <Button
                                            circular
                                            icon="settings"
                                            style={{
                                              position: "absolute",
                                              left: 115,
                                              top: -12,
                                              cursor: "pointer",
                                              padding: "4px 9px 7px 9px",
                                            }}
                                            onClick={() => {
                                              setSelectedImage(imageData);
                                              setDeleteImagePopupOpen(true);
                                            }}
                                          >
                                            x
                                          </Button>
                                        )}
                                      </div>
                                    </Grid.Column>
                                  );
                                })}
                            </Grid>
                          </Grid.Column>

                          <Grid.Column
                            disabled={currentStatus > 2}
                            width={16}
                            style={{ marginTop: 5 }}
                          >
                            {(!hoseMakerImages ||
                              hoseMakerImages.length < 4) && (
                              <HoseMakerImageUploader
                                isUploadingHoseMakerImage={
                                  isUploadingHoseMakerImage
                                }
                                uploadHoseMakerImage={uploadHoseMakerImage}
                                setIsUploadingHoseMakerImage={
                                  setIsUploadingHoseMakerImage
                                }
                              />
                            )}
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </div>
                  ) : null}
                </div>
              </div>
            </Grid>
          </Grid.Column>
          <Grid.Column
            computer={3}
            tablet={16}
            mobile={16}
            style={{ paddingRight: 0, paddingTop: 0 }}
          >
            <div className="hose-maker-container">
              <div className="hose-maker-scroll">
                <Grid disabled={currentStatus > 2}>
                  <Grid.Column width={2} style={{ paddingRight: 0 }}>
                    <Image
                      width={18}
                      src={rightArrow}
                      wrapped
                      style={{ marginTop: -2 }}
                    />
                  </Grid.Column>
                  <Grid.Column width={14}>
                    <Header as="h5" style={{ marginTop: 2 }}>
                      Bill of Materials
                    </Header>
                  </Grid.Column>
                  <Grid.Column
                    width={16}
                    style={{ marginTop: -5, paddingTop: 0 }}
                  >
                    {formData && formData.step1_Hose ? (
                      <div>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          Hose:
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {`${formData.step1_Barcode} \xa0  x \xa0  1`}
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {formData.step1_Name}
                        </span>
                      </div>
                    ) : null}
                    {formData && formData.oal ? (
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          Cut Length:
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>{`${
                          formData.oal + formData.oalDecimal
                        }${userCountryCode === "US" ? " inches" : "mm"}`}</span>
                      </div>
                    ) : null}
                    {formData && formData.step2_LHF ? (
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          LH Fitting:
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {`${formData.step2_Barcode} \xa0  x \xa0  1`}
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {formData.step2_Name}
                        </span>
                      </div>
                    ) : null}
                    {formData && formData.step3_LFerrule ? (
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          LH Ferrule:
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {`${formData.step3_Barcode} \xa0  x \xa0  1`}
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {formData.step3_Name}
                        </span>
                      </div>
                    ) : null}
                    {formData && formData.step4_RHF ? (
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          RH Fitting:
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {`${formData.step4_Barcode} \xa0  x \xa0  1`}
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {formData.step4_Name}
                        </span>
                      </div>
                    ) : null}
                    {formData && formData.step5_RFerrule ? (
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          RH Ferrule:
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {`${formData.step5_Barcode} \xa0  x \xa0  1`}
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {formData.step5_Name}
                        </span>
                      </div>
                    ) : null}
                    {formData && formData.orientation ? (
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          Orientation:
                        </span>
                        <br />
                        <span style={{ fontSize: 12 }}>
                          {`${formData.orientation}°`}
                        </span>
                      </div>
                    ) : null}
                    {formData &&
                    formData.others &&
                    formData.others.length > 0 ? (
                      <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 14, fontWeight: "bold" }}>
                          Other items:
                        </span>
                        {formData?.others?.map((data, index) => {
                          return (
                            <div key={index}>
                              <span style={{ fontSize: 12 }}>
                                {`${data.barcode} \xa0  x \xa0  ${data.quantity}`}
                              </span>
                              <br />
                              <span style={{ fontSize: 12 }}>{data.name}</span>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </Grid.Column>
                </Grid>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default HoseMakerNew;
