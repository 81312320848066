import React from "react";
import NumericInput from "react-numeric-input";
import PropTypes from "prop-types";
import { Card, Grid, Icon, Popup } from "semantic-ui-react";

const NumericInputDiscount = ({
  min = 1,
  max = 100000,
  value = 1,
  onChangeHandler,
  onKeyDownHandler,
  onBlurHandler,
  onInputHandler,
  onClearHandler,
  precision = 0,
}) => {
  return (
    <Grid style={{ padding: 0, margin: 0 }}>
      <Grid.Column width={14} style={{ padding: 0, margin: 0 }}>
        <NumericInput
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
          onBlur={onBlurHandler}
          onInput={onInputHandler}
          name="quantity"
          min={min}
          max={max}
          precision={precision}
          step={1}
          value={value}
          strict
          className="form-control numeric-input-discount"
        />
        {value >= 0 ? (
          <Popup
            position="top center"
            trigger={
              <Icon
                style={{
                  position: "absolute",
                  top: 7,
                  right: 25,
                  fontSize: 16,
                  color: "#979fa8",
                }}
                link
                name="close"
                onClick={onClearHandler}
              />
            }
            content="Clear"
            inverted
          />
        ) : null}
      </Grid.Column>
      <Grid.Column width={2} style={{ padding: 0, margin: 0, marginTop: 1 }}>
        <Card
          style={{
            padding: 0,
            margin: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          <Card.Content
            style={{ padding: 7.1, margin: 0, fontWeight: "bolder" }}
          >
            <div style={{ fontWeight: "bolder", textAlign: "center" }}>
              <span>%</span>
            </div>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid>
  );
};

NumericInputDiscount.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  onChangeHandler: PropTypes.func,
  onKeyDownHandler: PropTypes.func,
  onBlurHandler: PropTypes.func,
};

export default NumericInputDiscount;
