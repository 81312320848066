import React, { useEffect, useRef, useState } from "react";
import "./settings.css";
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Loader,
  Modal,
  Table,
  Popup,
} from "semantic-ui-react";
import MetaData from "../../components/MetaData";
import Collapsible from "react-collapsible";
import boapod from "../../assets/images/pages/find-boapod.png";
import { useWindowSize } from "../../utils/utils";
import {
  apiAddAssetLocation,
  apiAddContact,
  apiAddCustomer,
  apiAddNotification,
  apiAddStockLocation,
  apiCancelInviteUser,
  apiDeleteAssetLocation,
  apiDeleteContact,
  apiDeleteCustomer,
  apiDeleteNotification,
  apiDeleteStockLocation,
  apiDeleteUser,
  apiGetAssetLocationsList,
  apiGetCustomersList,
  apiGetCustomPricing,
  apiGetMyBOAPodList,
  apiGetMyUsersList,
  apiGetMyUsersListWithCurrent,
  apiGetNotificationList,
  apiGetStockLocationList,
  apiGetUser,
  apiGetUserTypeList,
  apiGetVisibilityOptions,
  apiInviteUser,
  apiRemoveCompanyLogo,
  apiResendInviteUser,
  apiSaveCustomersAndContacts,
  apiUpdateAssetLocation,
  apiUpdateCustomPricing,
  apiUpdatePodAvailability,
  apiUpdatePodContactDetails,
  apiUpdateStockLocation,
  apiUpdateUser,
  apiUploadCompanyLogo,
} from "./settings.api";
import { industries } from "../../constants/appConstants";
import { tempData } from "./data";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Papa from "papaparse";
import CompanyImageUploader from "./companyImageUploader";
import { refreshToken, refreshTokenAndReload } from "../Auth/auth.api";
import Autocomplete from "react-google-autocomplete";
import XeroIntegrationComponent from "../../components/Xero/xeroAuth";
import MarkerImage from "../../assets/images/marker.png";
import AssetLocationRS23 from "./AssetLocationRS23";
import certificateIcon from "../../assets/images/certificate.svg";
import certificateUploadIcon from "../../assets/images/certificate-upload.svg";
import timeIcon from "../../assets/images/time.svg";
import TrainingCertificate from "./TrainingCertificate";
import {
  borderColor,
  color,
  fontWeight,
  lineHeight,
  padding,
  textAlign,
} from "@mui/system";
import emailIcon from "../../assets/images/email-icon.png";

const Settings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [initLoaded, setInitLoaded] = useState(false);
  const [editPodOpen, setEditPodOpen] = useState(false);
  const [editPodOpen2, setEditPodOpen2] = useState(false);
  const [openRemoveUser, setOpenRemoveUser] = useState(false);
  const [podsLoading, setPodsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isResendloading, setIsResendloading] = useState(false);
  const [isCanceloading, setIsCancelLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [formData, setFormData] = useState({});
  const [userList, setUserList] = useState([]);
  const [userListWithCurrent, setUserListWithCurrent] = useState([]);
  const [podList, setPodList] = useState([]);
  const [userTypeList, setUserTypeList] = useState([]);
  const [stocklocationList, setStocklocationList] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [stocklocationListFiltered, setStocklocationListFiltered] = useState(
    []
  );
  const [availabilityList, setAvailabilityList] = useState([]);
  const [availabilitySelected, setAvailabilitySelected] = useState(0);
  const [selectedPod, setSelectedPod] = useState(0);
  const [currentId, setCurrentId] = useState(0);
  const [accountType, setAccountType] = useState(0);
  const [userType, setUserType] = useState(0);
  const [userCountryCode, setUserCountryCode] = useState("");
  const [countries, setCountries] = useState([]);

  //Customers and Contacts

  const [addContactOpen, setAddContactOpen] = useState(false);
  const [openRemoveCustomer, setOpenRemoveCustomer] = useState(false);
  const [openDeleteCustomer, setOpenDeleteCustomer] = useState(false);
  const [openRemoveContact, setOpenRemoveContact] = useState(false);
  const [contactSaved, setContactSaved] = useState(false);
  const [customersLoading, setCustomersLoading] = useState(false);
  const [onCustomerEdit, setOnCustomerEdit] = useState(false);
  const [isAddCompany, setIsAddCompany] = useState(false);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [contactFormData, setContactFormData] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedContact, setSelectedContact] = useState({});
  const [jsonResult, setJsonResult] = useState([]);
  const [jsonResultData, setJsonResultData] = useState([]);

  // Stock Location
  const [addStockLocationOpen, setAddStockLocationOpen] = useState(false);
  const [deleteStockLocationOpen, setDeleteStockLocationOpen] = useState(false);
  const [deleteStockLocationErrorOpen, setDeleteStockLocationErrorOpen] =
    useState(false);
  const [deleteStockLocationError, setDeleteStockLocationError] = useState({});
  const [stockFormData, setStockFormData] = useState({});
  const [stockSaved, setStockSaved] = useState(false);
  const [boaPodsOpen, setBoaPodsOpen] = useState(false);

  // Company Logo
  const [uploadLogoOpen, setUploadLogoOpen] = useState(false);
  const [removeLogoOpen, setRemoveLogoOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [imageWidth, setImageWidth] = useState(null);

  // Asset Locations
  const [assetLocationsList, setAssetLocationsList] = useState([]);
  const [addAssetLocationOpen, setAddAssetLocationOpen] = useState(false);
  const [assetLocationData, setAssetLocationData] = useState({});
  const [deleteAssetLocationOpen, setDeleteAssetLocationOpen] = useState(false);
  const [assetSaved, setAssetSaved] = useState(false);

  // RS23 Asset Location
  const [viewRS23, setViewRS23] = useState(false);

  // RS23 Training Certificate
  const [userData, setUserData] = useState(null);
  const [viewTrainingCertificate, setViewTrainingCertificate] = useState(false);

  // Custom pricing
  const [pricingLoading, setPricingLoading] = useState(false);
  const [editCGM, setEditCGM] = useState(false);
  const [editPricing, setEditPricing] = useState(false);
  const [pricingData, setPricingData] = useState({
    useBoaRrp: true,
    customGlobalMarkup: null,
    allowCustomerDiscounts: null,
    labourFee: null,
    travel: null,
    crimpFeeEach: 0,
    crimpFeeMarkupPercent: null,
    lastUpdatedBy: null,
    lastUpdatedDateTime: null,
    useEach: true,
  });

  // Xero
  const [xeroAuthUrl, setXeroAuthUrl] = useState("");
  const [isXeroConnected, setIsXeroConnected] = useState(false);

  // Notification
  const [notificationData, setNotificationData] = useState(null);
  const [addNotificationOpen, setAddNotificationOpen] = useState(false);
  const [deleteNotificationOpen, setDeleteNotificationOpen] = useState(false);
  const [notificationSaved, setNotificationSaved] = useState(false);
  const [notifcationUsers, setNotifcationUsers] = useState(false);

  const handleIntegrationComplete = (tokenData) => {
    // Handle token data as needed (e.g., store it in state or localStorage)
    console.log("Integration Complete:", tokenData);
  };

  const csvRef = useRef();
  const size = useWindowSize();
  const imgRef = useRef(null);
  const locationRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const userName = localStorage.getItem("user");
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          let resultData = result.data;

          resultData.map(async (customer) => {
            customer.id = 0;
            customer.isDeleted = false;
            customer.userName = userName;
            customer.companyName = customer["Customer Name"];
            customer.customerName = customer["Customer Name"];
            customer.contactName = customer["Contact Name"];
            customer.contactNumber = customer["Contact Number"];
            customer.email = customer["Email Address"];
            if (customer.email) {
              customer.email = customer.email.trim().toLowerCase();
              if (!isValidEmail(customer.email)) {
                customer.hasInvalidEmail = true;
              }
            }
          });

          console.log(resultData);

          let data = resultData.filter((obj) => {
            return (
              (obj.customerName !== undefined &&
                obj.customerName !== null &&
                obj.customerName !== "") ||
              (obj.contactName !== undefined &&
                obj.contactName !== null &&
                obj.contactName !== "") ||
              (obj.contactNumber !== undefined &&
                obj.contactNumber !== null &&
                obj.contactNumber !== "") ||
              (obj.email !== undefined &&
                obj.email !== null &&
                obj.email !== "")
            );
          });

          let list = data.reduce((result, currentItem) => {
            currentItem.customerId = 0;

            const group = result.find(
              (groupItem) => groupItem.companyName === currentItem.companyName
            );

            if (group) {
              group.isDeleted = false;
              group.userName = userName;
              group.customerContacts.push(currentItem);
            } else {
              result.push({
                isDeleted: false,
                userName: userName,
                companyName: currentItem.companyName,
                customerContacts: [currentItem],
              });
            }

            return result;
          }, []);

          setJsonResultData(list);
          setJsonResult(data);
        },
        header: true, // Set this to true if your CSV file has headers
      });
    }
  };

  const generateCSV = () => {
    const csvContent = [
      ["Customer Name", "Contact Name", "Contact Number", "Email Address"],
      // Add more data rows here
    ];

    const csvRows = csvContent.map((row) => row.join(",")); // Join each row with a comma

    return csvRows.join("\n"); // Join rows with a newline character
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    //Get User's country data and country list
    const countryCode = localStorage.getItem("userCountryCode");
    setUserCountryCode(countryCode);
    const countryList = JSON.parse(localStorage.getItem("countries"));
    setCountries(countryList);
  }, []);

  useEffect(() => {
    if (size.width <= 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [size]);

  useEffect(() => {
    if (customers) {
      setTimeout(function () {
        setCustomersLoading(false);
      }, 300);
    }
  }, [customers]);

  useEffect(() => {
    const accountTypeData = localStorage.getItem("accountType");
    setAccountType(Number(accountTypeData));
    const userTypeData = localStorage.getItem("userType");
    setUserType(Number(userTypeData));
    const xeroAuthUrlData = localStorage.getItem("xeroAuthUrl");
    setXeroAuthUrl(xeroAuthUrlData);
    const isXeroConnectedData = localStorage.getItem("isXeroConnected");
    setIsXeroConnected(isXeroConnectedData === "true" ? true : false);

    const delayDebounceFn = setTimeout(async () => {
      if (Number(accountTypeData) !== 0) {
        getCustomersData();
        getMyPodsData();
        getMyUsersData();
        await getMyUsersWithCurrentData();
        getMyUsersTypesData();
        getStockLocationsData();
        getVisibilityOptionsData();
        getAssetLocationsData();
        getCustomPricingData();
        getNotificationsData();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [initLoaded]);

  const getCustomersData = async () => {
    setCustomersLoading(true);
    const result = await apiGetCustomersList();
    if (result) {
      setCustomers(result);
    }
  };

  const getMyUsersData = async () => {
    setIsLoading(true);
    const result = await apiGetMyUsersList();
    if (result) {
      const delayDebounceFn = setTimeout(() => {
        const currentUserIndex = result.findIndex(
          (user) => user.email === localStorage.getItem("user")
        );
        if (userData) {
          const currentUserData = result.find(
            (user) => user.email === userData.email
          );
          if (currentUserData) {
            setUserData(currentUserData);
          }
        }
        if (currentUserIndex !== -1) {
          const [item] = result.splice(currentUserIndex, 1);
          result.unshift(item);
        }
        setUserList(result);
        setIsLoading(false);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  };

  const getMyUsersWithCurrentData = async () => {
    const result = await apiGetMyUsersListWithCurrent();
    if (result) {
      let notifcationUsersList = result.map((item, i) => {
        item.text = `${item.fullName} \u00A0\u00A0\u00A0
        \u00A0\u00A0 ${item.email}`;
        item.content = (
          <Grid>
            <Grid.Column
              width={12}
              style={{
                padding: 0,
                paddingTop: 10,
                paddingLeft: 10,
                paddingBottom: 8,
                fontWeight: "bolder",
              }}
            >
              {item.fullName}
            </Grid.Column>
            <Grid.Column
              width={4}
              style={{
                padding: 0,
                paddingTop: 10,
                paddingRight: 10,
                textAlign: "right",
              }}
            >
              {item.userTypeName}
            </Grid.Column>
            <Grid.Column
              width={16}
              style={{ padding: 0, paddingLeft: 10, paddingBottom: 15 }}
            >
              {item.email}
            </Grid.Column>
          </Grid>
        );
        return item;
      });
      setNotifcationUsers(notifcationUsersList);
      setUserListWithCurrent(result);
    }
  };

  const getMyPodsData = async () => {
    setPodsLoading(true);
    const result = await apiGetMyBOAPodList();
    if (result) {
      setPodList(result);
      setPodsLoading(false);
    } else {
      setPodsLoading(false);
    }
  };

  const getUserData = async (id) => {
    try {
      const result = await apiGetUser(id);
      if (result) {
        if (result.statusCode === 403) {
          showErrorToast("You don't have permission to edit this user.");
        } else {
          setFormData(result);
          setIsOpen(true);
        }
      } else {
        showErrorToast("Something went wrong");
      }
    } catch {
      showErrorToast("You don't have permission to edit this user.");
    }
  };

  const getMyUsersTypesData = async () => {
    const result = await apiGetUserTypeList();
    if (result) {
      setUserTypeList(result);
    }
  };

  const getStockLocationsData = async () => {
    const result = await apiGetStockLocationList();
    if (result) {
      setStocklocationList(result);
      const list = result.filter(
        (item) => item.stockLocationType === "OtherStockLocation"
      );
      setStocklocationListFiltered(list);
    } else {
      setStocklocationList([]);
    }
  };

  const getNotificationsData = async () => {
    const result = await apiGetNotificationList();
    if (result) {
      setNotificationList(result);
    } else {
      setNotificationList([]);
    }
  };

  const getVisibilityOptionsData = async () => {
    const result = await apiGetVisibilityOptions();
    if (result) {
      setAvailabilityList(result);
    }
  };

  const getAssetLocationsData = async () => {
    const result = await apiGetAssetLocationsList();
    if (result) {
      setAssetLocationsList(result);
    }
  };

  const getCustomPricingData = async () => {
    const result = await apiGetCustomPricing();
    if (result) {
      setPricingData(result);
    }
  };

  const onNewUser = () => {
    setIsOpen(true);
    setFormData(tempData);
  };

  function handleChange(dataType, value) {
    let newState = [];
    newState.push(formData);
    let jobData = newState.map((item, i) => {
      if (i == 0) {
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setFormData(jobData[0]);
  }

  function handleStockLocationChange(dataType, value) {
    let newState = [];
    newState.push(stockFormData);
    let data = newState.map((item, i) => {
      if (i == 0) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    setStockFormData(data[0]);
  }

  function handleContactChange(dataType, value) {
    let newState = [];
    newState.push(contactFormData);
    let data = newState.map((item, i) => {
      if (i == 0) {
        if (dataType === "companyName" && value) {
          item.id = 0;
          item.customerId = 0;
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });
    setContactFormData(data[0]);
  }

  function handleCustomerChange(dataType, value, index) {
    let newState = customers;
    let data = newState.map((item, i) => {
      if (i == index) {
        console.log("test");
        return { ...item, [dataType]: value };
      }
      return item;
    });

    console.log(data);
    setCustomers(data);
  }

  function handleCustomerContactChange(dataType, value, customerId, index) {
    let newState = customers;
    console.log(customerId);
    let data = newState.map((item, i) => {
      if (item.id == customerId) {
        item.customerContacts[index][dataType] = value;
        return item;
      }
      return item;
    });
    setCustomers(data);
  }

  function handleAssetLocationChange(dataType, value) {
    let newState = [];
    newState.push(assetLocationData);
    let data = newState.map((item, i) => {
      if (i == 0) {
        if (dataType == "customerLocation") {
          if (locationRef && locationRef.current)
            return {
              ...item,
              [dataType]: locationRef.current.refs.input.value,
            };
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });
    setAssetLocationData(data[0]);
  }

  function handlePricingChange(dataType, value) {
    if (typeof value !== "boolean") {
      if (value === "") {
        value = 0;
      }
      if (Number(value) < 0) {
        value = "0";
      }
      if (value.length > 1 && value[0] === "0") {
        value = value.slice(1);
      }
    }
    let newState = [];
    newState.push(pricingData);
    let data = newState.map((item, i) => {
      if (i == 0) {
        if (dataType === "useEach" && value === true) {
          item.crimpFeeEach = 10;
          item.crimpFeeMarkupPercent = null;
        }
        if (dataType === "useEach" && value === false) {
          item.crimpFeeEach = null;
          item.crimpFeeMarkupPercent = 10;
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });
    setPricingData(data[0]);

    if (dataType === "allowCustomerDiscounts" || dataType === "useBoaRrp") {
      onSubmitCustomPricing(data[0]);
    }
  }

  const onAddressSelection = (place) => {
    handleAssetLocationChange(
      "locationAddress",
      locationRef.current.refs.input.value
    );
  };

  const onSubmitAddCustomer = async () => {
    setContactSaved(true);
    if (
      (!contactFormData.companyName || contactFormData.companyName === "") &&
      isAddCompany
    ) {
      showErrorToast("Please complete all required fields");
      return false;
    }
    if (!contactFormData.customerId && !isAddCompany) {
      showErrorToast("Please complete all required fields");
      return false;
    }
    const userName = localStorage.getItem("user");
    if (contactFormData.customerId) {
      if (contactFormData.contactName) {
        if (contactFormData.contactNumber) {
          const phoneValid = isValidPhoneNumber(
            contactFormData.contactNumber ? contactFormData.contactNumber : ""
          );
          if (!phoneValid) {
            showErrorToast("Invalid phone number");
            return false;
          }
        }
        if (contactFormData.email) {
          contactFormData.email = contactFormData.email.trim().toLowerCase();
          if (!isValidEmail(contactFormData.email)) {
            showErrorToast("Invalid email address");
            return false;
          }
        }
      } else {
        showErrorToast("Please complete all required fields");
        return false;
      }
      return onSubmitAddContact(contactFormData.customerId);
    }
    try {
      const contactData = await apiAddCustomer(contactFormData, userName);
      if (contactData) {
        if (contactFormData.contactName) {
          onSubmitAddContact(contactData.id);
        } else {
          getCustomersData();
          setAddContactOpen(false);
          setContactFormData({});
          setContactSaved(false);
          showSuccessToast(
            <div>
              <i className="check icon" /> Customer Added Successfully
            </div>
          );
          setIsOpen(false);
          setIsLoading(false);
        }
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onSubmitCustomPricing = async (pricingPaylod) => {
    setIsLoading(true);
    try {
      const priceData = await apiUpdateCustomPricing(pricingPaylod);
      if (priceData) {
        setIsLoading(false);
        getCustomPricingData();
      } else {
        showErrorToast("Something went wrong");
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onSubmitAddStockLocation = async () => {
    setIsLoading(true);
    setStockSaved(true);
    try {
      const stockData = await apiAddStockLocation(stockFormData);
      if (stockData) {
        showSuccessToast(
          <div>
            <i className="check icon" /> Stock Location Added Successfully
          </div>
        );
        setStockFormData({});
        setAddStockLocationOpen(false);
        setIsLoading(false);
        setStockSaved(false);
        getStockLocationsData();
      } else {
        setStockFormData({});
        setAddStockLocationOpen(false);
        setIsLoading(false);
        setStockSaved(false);
        getStockLocationsData();
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onUpdateStockLocation = async () => {
    setIsLoading(true);
    try {
      const stockData = await apiUpdateStockLocation(stockFormData);
      if (stockData) {
        showSuccessToast(
          <div>
            <i className="check icon" /> Stock Location Updated Successfully
          </div>
        );
        setStockFormData({});
        setAddStockLocationOpen(false);
        setIsLoading(false);
        setStockSaved(false);
        getStockLocationsData();
      } else {
        showErrorToast("Something went wrong");
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onAddAssetLocation = async () => {
    setIsLoading(true);
    console.log(assetLocationData);
    if (
      !assetLocationData.locationName ||
      !assetLocationData.locationAddress ||
      assetLocationData.locationName === null ||
      assetLocationData.locationAddress === null ||
      assetLocationData.locationName === "" ||
      assetLocationData.locationAddress === ""
    ) {
      setAssetSaved(true);
      setIsLoading(false);
      showErrorToast("Please complete all required fields");
      return false;
    }
    try {
      const assetData = await apiAddAssetLocation(assetLocationData);
      if (assetData) {
        showSuccessToast(
          <div>
            <i className="check icon" /> Asset Location Added Successfully
          </div>
        );
        setAssetLocationData({});
        setAddAssetLocationOpen(false);
        setIsLoading(false);
        getAssetLocationsData();
        setAssetSaved(false);
      } else {
        setAssetLocationData({});
        setAddAssetLocationOpen(false);
        setIsLoading(false);
        getAssetLocationsData();
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onAddNotification = async () => {
    if (!notificationData?.id) {
      setNotificationSaved(true);
      return false;
    }
    setIsLoading(true);
    try {
      const assetData = await apiAddNotification(notificationData);
      if (assetData) {
        showSuccessToast(
          <div>
            <i className="check icon" /> Notification Receiver Added
            Successfully
          </div>
        );
        setNotificationData(null);
        setIsLoading(false);
        getNotificationsData();
        setAddNotificationOpen(false);
        setNotificationSaved(false);
      } else {
        setIsLoading(false);
        getNotificationsData();
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onUpdateAssetLocation = async () => {
    setIsLoading(true);
    if (
      !assetLocationData.locationName ||
      !assetLocationData.locationAddress ||
      assetLocationData.locationName === null ||
      assetLocationData.locationAddress === null ||
      assetLocationData.locationName === "" ||
      assetLocationData.locationAddress === ""
    ) {
      setAssetSaved(true);
      setIsLoading(false);
      showErrorToast("Please complete all required fields");
      return false;
    }
    try {
      const assetData = await apiUpdateAssetLocation(assetLocationData);
      if (assetData) {
        showSuccessToast(
          <div>
            <i className="check icon" /> Asset Location Updated Successfully
          </div>
        );
        setAssetLocationData({});
        setAddAssetLocationOpen(false);
        setIsLoading(false);
        getAssetLocationsData();
        setAssetSaved(false);
      } else {
        showErrorToast("Something went wrong");
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onSubmitAddContact = async (id) => {
    try {
      const contactData = await apiAddContact(contactFormData, id);
      if (contactData) {
        getCustomersData();
        setAddContactOpen(false);
        setContactFormData({});
        showSuccessToast(
          <div>
            <i className="check icon" /> Customer and Contact Added Successfully
          </div>
        );
        setIsOpen(false);
        setIsLoading(false);
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onSubmitInviteUser = async () => {
    if (!formData?.boahubLocationId) {
      showErrorToast("Stock Location is required");
      return false;
    }
    formData.email = formData.email.trim().toLowerCase();
    setIsLoading(true);
    try {
      const userData = await apiInviteUser(formData);
      if (userData) {
        getMyUsersData();
        showSuccessToast(
          <div>
            <i className="check icon" /> User Added Successfully
          </div>
        );
        setIsOpen(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch {
      setIsLoading(false);
    }
  };

  const onSaveCustomersAndContacts = async () => {
    let hasError = false;
    jsonResult.map((data) => {
      if (!data.customerName || data.hasInvalidEmail) {
        hasError = true;
      }
    });

    if (hasError) {
      showErrorToast("Please correct all data before importing");
      return false;
    }

    let postData = [];

    // mapping for csv upload
    if (jsonResult.length > 0) {
      postData = jsonResultData;
    } else {
      postData = customers;
    }

    postData.map((data) => {
      if (data.discountPercent !== null)
        data.discountPercent = Number(data.discountPercent);
    });

    setIsLoading(true);
    setCustomersLoading(true);
    setOnCustomerEdit(false);
    try {
      const data = await apiSaveCustomersAndContacts(postData);
      if (data) {
        getCustomersData();
        showSuccessToast(
          <div>
            <i className="check icon" /> Customers and Contacts Updated
            Successfully
          </div>
        );
        setCsvUploadOpen(false);
        setIsLoading(false);
        setJsonResult([]);
        setJsonResultData([]);
      } else {
        showErrorToast("Something went wrong");
        setCustomersLoading(false);
        setIsLoading(false);
      }
    } catch {
      showErrorToast("Something went wrong");
      setCustomersLoading(false);
      setIsLoading(false);
    }
  };

  const onResendInviteUser = async (id) => {
    setCurrentId(id);
    setIsResendloading(true);
    try {
      const userData = await apiResendInviteUser(id);
      console.log(userData);
      if (userData) {
        getMyUsersData();
        showSuccessToast(
          <div>
            <i className="check icon" /> Invitation sent successfully
          </div>
        );
        setOpenRemoveUser(false);
        setIsOpen(false);
        setIsResendloading(false);
      } else {
        showErrorToast("Something went wrong");
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsResendloading(false);
    }
  };

  const onCancelInviteUser = async (id) => {
    setCurrentId(id);
    setIsCancelLoading(true);
    try {
      const userData = await apiCancelInviteUser(id);
      console.log(userData);
      if (userData) {
        getMyUsersData();
        showSuccessToast(
          <div>
            <i className="check icon" /> Invitation cancelled
          </div>
        );
        setOpenRemoveUser(false);
        setIsOpen(false);
        setIsCancelLoading(false);
      } else {
        showErrorToast("Something went wrong");
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsCancelLoading(false);
    }
  };

  const onUpdateUser = async () => {
    formData.email = formData.email.trim().toLowerCase();
    setIsLoading(true);
    try {
      const userData = await apiUpdateUser(formData);
      if (userData) {
        getMyUsersData();
        showSuccessToast(
          <div>
            <i className="check icon" /> User Updated Successfully
          </div>
        );
        setIsOpen(false);
        setIsLoading(false);
      } else {
        showErrorToast("Something went wrong");
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onDeleteUser = async () => {
    setIsLoading(true);
    try {
      const userData = await apiDeleteUser(formData);
      if (userData) {
        getMyUsersData();
        showSuccessToast(
          <div>
            <i className="check icon" /> User Deleted Successfully
          </div>
        );
        setOpenRemoveUser(false);
        setIsOpen(false);
        setIsLoading(false);
      } else {
        showErrorToast("Something went wrong");
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onDeleteCustomer = async (includeContacts) => {
    setIsLoading(true);
    try {
      const customerData = await apiDeleteCustomer(
        includeContacts,
        selectedCustomer
      );
      if (customerData) {
        getCustomersData();
        showSuccessToast(
          <div>
            <i className="check icon" /> Customer Deleted Successfully
          </div>
        );
        setSelectedCustomer({});
        setOpenRemoveCustomer(false);
        setOpenDeleteCustomer(false);
        setIsLoading(false);
      } else {
        showErrorToast("Something went wrong");
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onDeleteContact = async () => {
    setIsLoading(true);
    try {
      const contactData = await apiDeleteContact(selectedContact);
      if (contactData) {
        getCustomersData();
        showSuccessToast(
          <div>
            <i className="check icon" /> Contact Deleted Successfully
          </div>
        );
        setSelectedContact({});
        setOpenRemoveContact(false);
        setIsLoading(false);
      } else {
        showErrorToast("Something went wrong");
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onDeleteStockLocation = async () => {
    setIsLoading(true);

    const stockData = await apiDeleteStockLocation(stockFormData);
    if (stockData) {
      if (stockData.errors) {
        setDeleteStockLocationError(stockData);
        setDeleteStockLocationErrorOpen(true);
        setDeleteStockLocationOpen(false);
        setIsLoading(false);
      }

      getStockLocationsData();
      setStockFormData({});
      showSuccessToast(
        <div>
          <i className="check icon" /> Stock Location Deleted Successfully
        </div>
      );
      setDeleteStockLocationOpen(false);
      setIsLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setDeleteStockLocationOpen(false);
      setIsLoading(false);
    }
  };

  const onDeleteAssetLocation = async () => {
    setIsLoading(true);

    const assetData = await apiDeleteAssetLocation(assetLocationData);
    if (assetData) {
      getAssetLocationsData();
      setAssetLocationData({});
      showSuccessToast(
        <div>
          <i className="check icon" /> Asset Location Deleted Successfully
        </div>
      );
      setDeleteAssetLocationOpen(false);
      setAddAssetLocationOpen(false);
      setIsLoading(false);
      setAssetSaved(false);
    } else {
      showErrorToast("Something went wrong");
      setDeleteAssetLocationOpen(false);
      setIsLoading(false);
    }
  };

  const onDeleteNotification = async () => {
    setIsLoading(true);
    try {
      const result = await apiDeleteNotification(notificationData.id);
      if (result) {
        getNotificationsData();
        showSuccessToast(
          <div>
            <i className="check icon" /> Quote Acceptance Email Deleted
            Successfully
          </div>
        );
        setIsLoading(false);
        setDeleteNotificationOpen(false);
        setNotificationData(null);
      } else {
        showErrorToast("Something went wrong");
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const turnPodAvailability = async (pod, isActive) => {
    setIsLoading(true);
    try {
      const podData = await apiUpdatePodAvailability(
        pod,
        isActive,
        availabilitySelected
      );
      if (podData) {
        showSuccessToast(
          <div>
            <i className="check icon" /> POD Availability Updated Successfully
          </div>
        );
        setEditPodOpen(false);
        setEditPodOpen2(false);
        setIsLoading(false);
      } else {
        handlePodChange("status", !isActive, selectedPod);
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } catch {
      handlePodChange("status", !isActive, selectedPod);
      showErrorToast("Please complete all required fields");
      setIsLoading(false);
    }
  };

  function getUserFromId(id) {
    let userData = {};
    userListWithCurrent.map((user, i) => {
      if (user.id === id) {
        userData = user;
      }
    });

    return userData;
  }

  const onSavePod = async (pod) => {
    try {
      const podData = await apiUpdatePodContactDetails(pod);
      if (podData) {
        showSuccessToast(
          <div>
            <i className="check icon" /> Contact Details Saved Successfully
          </div>
        );
      } else {
        showErrorToast("Something went wrong");
      }
    } catch {
      showErrorToast("Something went wrong");
    }
  };

  function handlePodChange(dataType, value, index) {
    let newState = [];
    newState = podList;
    let podListData = newState.map((item, i) => {
      if (i == index) {
        if (dataType === "userId") {
          console.log("test");
          const user = getUserFromId(value);
          item.driverName = user.fullName;
          item.email = user.email;
          console.log(item.driverName);
        }
        if (dataType === "status") {
          if (value === true) {
            turnPodAvailability(item, true);
            item.status = "online";
          }
          if (value === false) {
            item.status = "offline";
            turnPodAvailability(item, false);
          }
          return item;
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setPodList(podListData);
  }

  const getImage = async (image) => {
    if (image instanceof File) {
      const selectedFile = image;
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes

      try {
        const compressedImage = await compressImage(selectedFile);
        setImageData(compressedImage);
      } catch (error) {
        showErrorToast("Error compressing image: " + error);
      }
    }
  };

  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxDimension = 256; // Max width or height of the compressed image

          let width = img.width;
          let height = img.height;

          if (height > maxDimension) {
            width *= maxDimension / height;
            height = maxDimension;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.fillStyle = "#ffffff"; // White color
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const compressedFile = new File([blob], file.name, {
              type: "image/jpg", // Change the format if needed
            });
            resolve(compressedFile);
          }, "image/jpg"); // Change the format if needed
        };
      };

      reader.readAsDataURL(file);
    });
  };

  const getImageSrc = async (image) => {
    if (image) {
      setImageSrc(image);
    }
  };

  const onUploadCompanyLogo = async () => {
    setIsLoading(true);
    try {
      const logo = await apiUploadCompanyLogo(imageData);
      if (logo) {
        window.localStorage.setItem("relatedCompanyLogoUrl", logo.logoUrl);
        showSuccessToast(
          <div>
            <i className="check icon" /> Logo Updated Successfully
          </div>
        );
        setUploadLogoOpen(false);
        setIsLoading(false);
        setImageData(null);
        setImageSrc(null);
        refreshTokenAndReload();
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onRemoveCompanyLogo = async () => {
    setIsLoading(true);
    try {
      const logo = await apiRemoveCompanyLogo(imageData);
      if (logo) {
        window.localStorage.setItem("relatedCompanyLogoUrl", "");
        showSuccessToast(
          <div>
            <i className="check icon" /> Company Logo Removed Successfully
          </div>
        );
        setRemoveLogoOpen(false);
        setIsLoading(false);
        setImageData(null);
        refreshTokenAndReload();
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const getImageWidth = () => {
    if (imgRef.current) {
      const width = imgRef.current.width;
      setImageWidth(width);
    }
  };

  return accountType === 0 ? null : (
    <React.Fragment>
      <MetaData title="Settings" />
      <Grid columns={1} className="settings-container">
        <Grid.Row>
          <Grid.Column
            width={16}
            className="settings-main-section"
            style={{ paddingRight: 0 }}
          >
            <Modal
              size="mini"
              open={openRemoveUser}
              onClose={() => setOpenRemoveUser(false)}
            >
              <div style={{ position: "absolute", bottom: 100, left: 150 }}>
                {isLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  />
                ) : null}
              </div>
              <Modal.Content
                style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
              >
                <p>
                  Are you sure <br /> you want to delete user?
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => setOpenRemoveUser(false)}>NO</Button>
                <Button
                  style={{
                    backgroundColor: "rgb(102, 204, 35)",
                    color: "#fff",
                  }}
                  onClick={() => onDeleteUser(true)}
                >
                  YES
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal
              size="mini"
              open={openRemoveCustomer}
              onClose={() => setOpenRemoveCustomer(false)}
            >
              <div style={{ position: "absolute", bottom: 100, left: 150 }}>
                {isLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  />
                ) : null}
              </div>
              <Modal.Content
                style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
              >
                <p>
                  Are you sure <br /> you want to delete customer?
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => setOpenRemoveCustomer(false)}>NO</Button>
                <Button
                  style={{
                    backgroundColor: "rgb(102, 204, 35)",
                    color: "#fff",
                  }}
                  onClick={() => {
                    if (selectedCustomer.customerContacts?.length > 0) {
                      setOpenDeleteCustomer(true);
                      setOpenRemoveCustomer(false);
                    } else {
                      onDeleteCustomer(false);
                    }
                  }}
                >
                  YES
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal
              size="mini"
              open={openDeleteCustomer}
              onClose={() => setOpenDeleteCustomer(false)}
            >
              <div style={{ position: "absolute", bottom: 100, left: 150 }}>
                {isLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  />
                ) : null}
              </div>
              <Modal.Content
                style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
              >
                <p>
                  Do you want to delete all contacts <br />
                  attached to {selectedCustomer.companyName}
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => setOpenDeleteCustomer(false)}>
                  CANCEL
                </Button>
                <Button onClick={() => onDeleteCustomer(false)}>NO</Button>
                <Button
                  style={{
                    backgroundColor: "rgb(102, 204, 35)",
                    color: "#fff",
                  }}
                  onClick={() => onDeleteCustomer(true)}
                >
                  YES
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal
              size="mini"
              open={openRemoveContact}
              onClose={() => setOpenRemoveContact(false)}
            >
              <div style={{ position: "absolute", bottom: 100, left: 150 }}>
                {isLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  />
                ) : null}
              </div>
              <Modal.Content
                style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
              >
                <p>
                  Are you sure <br /> you want to delete contact?
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => setOpenRemoveContact(false)}>NO</Button>
                <Button
                  style={{
                    backgroundColor: "rgb(102, 204, 35)",
                    color: "#fff",
                  }}
                  onClick={() => onDeleteContact()}
                >
                  YES
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal size="mini" open={deleteStockLocationOpen}>
              <div style={{ position: "absolute", bottom: 100, left: 150 }}>
                {isLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  />
                ) : null}
              </div>
              <Modal.Content
                style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
              >
                <p>
                  Are you sure <br /> you want to delete stock location?
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => setDeleteStockLocationOpen(false)}>
                  NO
                </Button>
                <Button
                  style={{
                    backgroundColor: "rgb(102, 204, 35)",
                    color: "#fff",
                  }}
                  onClick={() => onDeleteStockLocation(true)}
                >
                  YES
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal size="mini" open={removeLogoOpen}>
              <div style={{ position: "absolute", bottom: 100, left: 150 }}>
                {isLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  />
                ) : null}
              </div>
              <Modal.Content
                style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
              >
                <p>
                  Are you sure <br /> you want to delete company logo?
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => setRemoveLogoOpen(false)}>NO</Button>
                <Button
                  style={{
                    backgroundColor: "rgb(102, 204, 35)",
                    color: "#fff",
                  }}
                  onClick={() => onRemoveCompanyLogo(true)}
                >
                  YES
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal size="mini" open={deleteAssetLocationOpen}>
              <div style={{ position: "absolute", bottom: 100, left: 150 }}>
                {isLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  />
                ) : null}
              </div>
              <Modal.Content
                style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
              >
                <p>
                  Are you sure <br /> you want to delete asset location?
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => setDeleteAssetLocationOpen(false)}>
                  NO
                </Button>
                <Button
                  style={{
                    backgroundColor: "rgb(102, 204, 35)",
                    color: "#fff",
                  }}
                  onClick={() => onDeleteAssetLocation(true)}
                >
                  YES
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal size="mini" open={deleteNotificationOpen}>
              <div style={{ position: "absolute", bottom: 100, left: 150 }}>
                {isLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  />
                ) : null}
              </div>
              <Modal.Header>DELETE NOTIFICATION RECEIVER</Modal.Header>
              <Modal.Content
                style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
              >
                <p>
                  Are you sure to delete asset the user from the email list?
                  <br />
                  <br />
                  {notificationData?.toNotifyEmail}
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  color="youtube"
                  onClick={() => setDeleteNotificationOpen(false)}
                >
                  NO
                </Button>
                <Button
                  style={{
                    backgroundColor: "rgb(102, 204, 35)",
                    color: "#fff",
                  }}
                  onClick={() => onDeleteNotification()}
                >
                  YES
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal size="mini" open={isOpen} onClose={() => setIsOpen(false)}>
              <div style={{ position: "absolute", bottom: 200, left: 150 }}>
                {isLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  />
                ) : null}
              </div>
              <Modal.Header>
                {formData.id ? "EDIT" : "ADD NEW"} USER
              </Modal.Header>
              <Modal.Content
                style={{
                  opacity: isLoading ? 0.2 : 1,
                }}
              >
                <Form size="mini">
                  <Form.Field>
                    <label>First Name</label>
                    <Input
                      placeholder="First Name"
                      value={formData?.firstName}
                      onChange={(e, data) =>
                        handleChange("firstName", data.value)
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Last Name</label>
                    <Input
                      placeholder="Last Name"
                      value={formData?.lastName}
                      onChange={(e, data) =>
                        handleChange("lastName", data.value)
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Phone Number</label>
                    <PhoneInput
                      international
                      defaultCountry={userCountryCode}
                      countryCallingCodeEditable={false}
                      placeholder="Phone Number"
                      value={formData?.mobileNumber}
                      onChange={(data) => handleChange("mobileNumber", data)}
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label>Email Address</label>
                    <Input
                      disabled={formData?.id}
                      placeholder="Email Address"
                      value={formData?.email}
                      onChange={(e, data) => handleChange("email", data.value)}
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label>Industry</label>
                    <Dropdown
                      placeholder="Industry"
                      selection
                      options={industries}
                      value={formData?.industryId?.toString()}
                      onChange={(e, data) =>
                        handleChange("industryId", data.value)
                      }
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label>User Type</label>
                    <Dropdown
                      placeholder="User Type"
                      selection
                      options={userTypeList}
                      value={formData?.userType}
                      onChange={(e, data) =>
                        handleChange("userType", data.value)
                      }
                    />
                  </Form.Field>
                  {userType === 2 ||
                  userType === 3 ||
                  userType === 5 ||
                  userType === 6 ? (
                    <Form.Field required>
                      <label>Stock Location</label>
                      <Dropdown
                        search
                        placeholder="Stock Location"
                        selection
                        options={stocklocationList}
                        value={formData?.boahubLocationId}
                        onChange={(e, data) =>
                          handleChange("boahubLocationId", data.value)
                        }
                      />
                    </Form.Field>
                  ) : null}
                  <Grid style={{ margin: "1rem" }}>
                    {accountType === 3 || !formData.id ? null : (
                      <>
                        <Grid.Column
                          width={8}
                          style={{
                            textAlign: "left",
                            padding: 0,
                            paddingTop: 5,
                          }}
                        >
                          <Form.Checkbox
                            inline
                            label="BUY PRICE"
                            checked={formData?.boahubShowPurchasePrice}
                            onChange={(e, data) =>
                              handleChange(
                                "boahubShowPurchasePrice",
                                data.checked
                              )
                            }
                          />
                        </Grid.Column>
                        <Grid.Column
                          width={8}
                          style={{
                            textAlign: "center",
                            padding: 0,
                            paddingTop: 5,
                          }}
                        >
                          <Form.Checkbox
                            inline
                            label="RRP PRICE"
                            checked={formData?.boahubShowRrp}
                            onChange={(e, data) =>
                              handleChange("boahubShowRrp", data.checked)
                            }
                          />
                        </Grid.Column>
                      </>
                    )}
                  </Grid>
                  {formData?.id ? (
                    <Grid>
                      <Grid.Column width={8} style={{ paddingRight: 5 }}>
                        <Button
                          size="mini"
                          color="youtube"
                          style={{
                            width: "100%",
                            marginLeft: "auto",
                          }}
                          onClick={() => setOpenRemoveUser(true)}
                        >
                          DELETE
                        </Button>
                      </Grid.Column>
                      <Grid.Column width={8} style={{ paddingLeft: 5 }}>
                        <Button
                          size="mini"
                          style={{
                            width: "100%",
                            backgroundColor: "rgb(102, 204, 35)",
                            color: "#fff",
                            marginLeft: "auto",
                          }}
                          onClick={() => onUpdateUser()}
                        >
                          SAVE
                        </Button>
                      </Grid.Column>
                    </Grid>
                  ) : (
                    <Button
                      size="mini"
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(102, 204, 35)",
                        color: "#fff",
                        marginLeft: "auto",
                      }}
                      onClick={() => onSubmitInviteUser()}
                    >
                      SEND
                    </Button>
                  )}
                </Form>
              </Modal.Content>
            </Modal>
            <Modal
              size="mini"
              open={editPodOpen}
              onClose={() => setEditPodOpen(false)}
            >
              {/* <Modal.Header>
                {formData.id ? "EDIT" : "ADD NEW"} USER
              </Modal.Header> */}
              <Modal.Content
                style={{
                  opacity: isLoading ? 0.2 : 1,
                }}
              >
                <Form size="mini" style={{ textAlign: "center" }}>
                  {/* <Form.Field required>
                    <label>User Type</label>
                    <Dropdown
                      placeholder="User Type"
                      selection
                      options={userTypeList}
                      value={formData?.userType}
                      onChange={(e, data) =>
                        handlePodChange("userType", data.value)
                      }
                    />
                  </Form.Field> */}
                  <Header as="h3">
                    Do you want to turn <br /> availability off?
                  </Header>
                  <Button
                    size="small"
                    style={{
                      width: "80%",
                      marginLeft: "auto",
                    }}
                    onClick={() => setEditPodOpen(false)}
                  >
                    NO
                  </Button>
                  <Button
                    size="small"
                    style={{
                      width: "80%",
                      backgroundColor: "rgb(102, 204, 35)",
                      color: "#fff",
                      marginLeft: "auto",
                      marginTop: 10,
                    }}
                    onClick={() => setEditPodOpen2(true)}
                  >
                    YES
                  </Button>
                </Form>
              </Modal.Content>
            </Modal>
            <Modal
              size="mini"
              open={editPodOpen2}
              onClose={() => setEditPodOpen2(false)}
            >
              <Modal.Content
                style={{
                  opacity: isLoading ? 0.2 : 1,
                }}
              >
                <Form size="small" style={{ textAlign: "center" }}>
                  <Header as="h3">
                    Turn availability of this <br /> POD back again in
                  </Header>
                  <Form.Field required>
                    <Dropdown
                      selection
                      options={availabilityList}
                      value={availabilitySelected}
                      onChange={(e, data) =>
                        setAvailabilitySelected(data.value)
                      }
                      style={{ width: "80%" }}
                    />
                  </Form.Field>
                  <Button
                    size="small"
                    style={{
                      width: "80%",
                      marginLeft: "auto",
                      marginTop: 10,
                    }}
                    onClick={() => setEditPodOpen2(false)}
                  >
                    CANCEL
                  </Button>
                  <Button
                    size="small"
                    style={{
                      width: "80%",
                      backgroundColor: "rgb(102, 204, 35)",
                      color: "#fff",
                      marginLeft: "auto",
                      marginTop: 10,
                    }}
                    onClick={() =>
                      handlePodChange("status", false, selectedPod)
                    }
                  >
                    TURN OFF
                  </Button>
                </Form>
              </Modal.Content>
            </Modal>
            <Modal size="tiny" open={addContactOpen}>
              <Modal.Header>ADD NEW CONTACT</Modal.Header>
              <Modal.Content>
                <Form size="tiny">
                  {!isAddCompany ? (
                    <Form.Field required>
                      <label>Company Name</label>
                      <Grid>
                        <Grid.Column width={12} style={{ paddingRight: 0 }}>
                          <Dropdown
                            className={
                              (contactFormData?.companyName === "" ||
                                contactFormData?.companyName === null ||
                                !contactFormData?.companyName) &&
                              !contactFormData?.customerId &&
                              contactSaved
                                ? "error"
                                : ""
                            }
                            placeholder="Company Name"
                            search
                            selection
                            options={customers}
                            style={{ width: "100%" }}
                            value={contactFormData.customerId}
                            onChange={(e, data) =>
                              handleContactChange("customerId", data.value)
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={4} style={{ paddingRight: 0 }}>
                          <Button
                            size="tiny"
                            onClick={() => {
                              handleContactChange("companyName", null);
                              setIsAddCompany(true);
                            }}
                          >
                            ADD NEW
                          </Button>
                        </Grid.Column>
                      </Grid>
                    </Form.Field>
                  ) : (
                    <Form.Field required>
                      <label>Company Name</label>
                      <Grid>
                        <Grid.Column width={12} style={{ paddingRight: 0 }}>
                          <Input
                            className={
                              (contactFormData?.companyName === "" ||
                                contactFormData?.companyName === null) &&
                              contactSaved
                                ? "error"
                                : ""
                            }
                            placeholder="Company Name"
                            value={contactFormData?.companyName}
                            onChange={(e, data) =>
                              handleContactChange("companyName", data.value)
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={4} style={{ paddingRight: 0 }}>
                          <Button
                            size="tiny"
                            onClick={() => {
                              handleContactChange("companyName", null);
                              setIsAddCompany(false);
                            }}
                          >
                            CANCEL
                          </Button>
                        </Grid.Column>
                      </Grid>
                    </Form.Field>
                  )}
                  <Form.Field>
                    <label>Contact Name</label>
                    <Input
                      className={
                        contactSaved &&
                        contactFormData?.customerId &&
                        !contactFormData?.contactName
                          ? "error"
                          : ""
                      }
                      disabled={
                        (contactFormData?.companyName === "" ||
                          !contactFormData?.companyName) &&
                        !contactFormData?.customerId
                      }
                      placeholder="Contact Name"
                      value={contactFormData?.contactName}
                      onChange={(e, data) =>
                        handleContactChange("contactName", data.value)
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Phone Number</label>
                    <PhoneInput
                      disabled={
                        (contactFormData?.companyName === "" ||
                          !contactFormData?.companyName) &&
                        !contactFormData?.customerId
                      }
                      className={
                        !isValidPhoneNumber(
                          contactFormData?.contactNumber
                            ? contactFormData?.contactNumber
                            : ""
                        ) &&
                        contactSaved &&
                        contactFormData?.companyName &&
                        contactFormData?.contactNumber
                          ? "error"
                          : ""
                      }
                      international
                      defaultCountry="NZ"
                      countryCallingCodeEditable={false}
                      placeholder="Phone Number"
                      value={contactFormData?.contactNumber}
                      onChange={(data) =>
                        handleContactChange("contactNumber", data)
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Email Address</label>
                    <Input
                      disabled={
                        (contactFormData?.companyName === "" ||
                          !contactFormData?.companyName) &&
                        !contactFormData?.customerId
                      }
                      className={
                        !isValidEmail(
                          contactFormData?.email ? contactFormData?.email : ""
                        ) &&
                        contactSaved &&
                        contactFormData?.companyName &&
                        contactFormData?.email
                          ? "error"
                          : ""
                      }
                      placeholder="Email Address"
                      value={contactFormData?.email}
                      onChange={(e, data) =>
                        handleContactChange("email", data.value)
                      }
                    />
                  </Form.Field>
                  <Grid style={{ marginTop: 20 }}>
                    <Grid.Column width={8} style={{ paddingRight: 5 }}>
                      <Button
                        size="tiny"
                        style={{
                          width: "100%",
                          marginLeft: "auto",
                        }}
                        onClick={() => {
                          setAddContactOpen(false);
                          setContactSaved(false);
                          setContactFormData({});
                        }}
                      >
                        CANCEL
                      </Button>
                    </Grid.Column>
                    <Grid.Column width={8} style={{ paddingLeft: 5 }}>
                      <Button
                        size="tiny"
                        style={{
                          width: "100%",
                          backgroundColor: "rgb(102, 204, 35)",
                          color: "#fff",
                          marginLeft: "auto",
                        }}
                        onClick={() => onSubmitAddCustomer()}
                      >
                        SAVE
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Form>
              </Modal.Content>
            </Modal>
            <Modal
              size="medium"
              open={csvUploadOpen}
              onClose={() => setCsvUploadOpen(false)}
            >
              <Modal.Header>IMPORT A FILE</Modal.Header>
              <Modal.Content>
                <Grid style={{ margin: 20, marginTop: 10, marginBottom: 0 }}>
                  <Grid.Column
                    width={16}
                    style={{
                      minHeight: 400,
                      maxHeight: 400,
                      overflowY: "auto",
                    }}
                  >
                    <Table sortable celled fixed>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Customer</Table.HeaderCell>
                          <Table.HeaderCell>Contact</Table.HeaderCell>
                          <Table.HeaderCell>Phone Number</Table.HeaderCell>
                          <Table.HeaderCell>Email Address</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {jsonResult.map(
                          ({
                            customerName,
                            contactName,
                            contactNumber,
                            email,
                            hasInvalidEmail,
                          }) => (
                            <Table.Row key={contactName}>
                              <Table.Cell
                                style={{
                                  border: !customerName ? "solid 1px red" : "",
                                }}
                              >
                                {!customerName ? (
                                  <Popup
                                    content={"Customer Name is required"}
                                    trigger={<span>{customerName}</span>}
                                    position="top center"
                                    inverted
                                  />
                                ) : (
                                  customerName
                                )}
                              </Table.Cell>
                              <Table.Cell>{contactName}</Table.Cell>
                              <Table.Cell>{contactNumber}</Table.Cell>
                              <Table.Cell
                                style={{
                                  border: hasInvalidEmail
                                    ? "solid 1px red"
                                    : "",
                                }}
                              >
                                {hasInvalidEmail ? (
                                  <Popup
                                    content={"Invalid email address"}
                                    trigger={<span>{email}</span>}
                                    position="top center"
                                    inverted
                                  />
                                ) : (
                                  email
                                )}
                              </Table.Cell>
                            </Table.Row>
                          )
                        )}
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                  <Grid.Column width={5} style={{ paddingRight: 5 }}>
                    <Button
                      size="small"
                      onClick={() => {
                        const csvData = generateCSV();
                        const blob = new Blob([csvData], { type: "text/csv" });
                        const url = URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = "Template.csv";
                        a.click();
                        URL.revokeObjectURL(url);
                      }}
                    >
                      DOWNLOAD TEMPLATE
                    </Button>
                  </Grid.Column>
                  <Grid.Column
                    width={11}
                    style={{ paddingLeft: 5, textAlign: "right" }}
                  >
                    <Button
                      size="small"
                      color="youtube"
                      onClick={() => {
                        setCsvUploadOpen(false);
                        setJsonResult([]);
                      }}
                      style={{ marginRight: 10 }}
                    >
                      CANCEL
                    </Button>
                    <Button size="small" onClick={() => csvRef.current.click()}>
                      LOAD NEW FILE
                    </Button>
                    <input
                      ref={csvRef}
                      type="file"
                      hidden
                      onChange={handleFileChange}
                    />
                    <Button
                      disabled={jsonResult.length === 0 || isLoading}
                      size="small"
                      style={{
                        backgroundColor: "rgb(102, 204, 35)",
                        color: "#fff",
                        marginLeft: "auto",
                      }}
                      onClick={() => onSaveCustomersAndContacts()}
                    >
                      {!isLoading ? "IMPORT" : "IMPORTING..."}
                    </Button>
                  </Grid.Column>
                </Grid>
              </Modal.Content>
            </Modal>
            <Modal size="tiny" open={addStockLocationOpen}>
              <Modal.Header>
                {stockFormData.id > 0 ? "EDIT" : "ADD"} STOCK LOCATION
              </Modal.Header>
              <div style={{ position: "absolute", bottom: 100, left: 250 }}>
                {isLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  />
                ) : null}
              </div>
              <Modal.Content>
                <Form size="tiny">
                  <Form.Field>
                    <label>Stock Location Name</label>
                    <Input
                      className={
                        stockSaved && !stockFormData?.stockLocationName
                          ? "error"
                          : ""
                      }
                      placeholder="Stock Location Name"
                      value={stockFormData?.stockLocationName}
                      onChange={(e, data) =>
                        handleStockLocationChange(
                          "stockLocationName",
                          data.value
                        )
                      }
                    />
                  </Form.Field>
                  <Grid style={{ marginTop: 20 }}>
                    <Grid.Column width={8} style={{ paddingRight: 5 }}>
                      <Button
                        size="tiny"
                        style={{
                          width: "100%",
                          marginLeft: "auto",
                        }}
                        onClick={() => {
                          setAddStockLocationOpen(false);
                          setStockFormData({});
                          setStockSaved(false);
                        }}
                      >
                        CANCEL
                      </Button>
                    </Grid.Column>
                    <Grid.Column width={8} style={{ paddingLeft: 5 }}>
                      <Button
                        size="tiny"
                        style={{
                          width: "100%",
                          backgroundColor: "rgb(102, 204, 35)",
                          color: "#fff",
                          marginLeft: "auto",
                        }}
                        onClick={() => {
                          if (stockFormData.id > 0) {
                            onUpdateStockLocation();
                          } else {
                            onSubmitAddStockLocation();
                          }
                        }}
                      >
                        SAVE
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Form>
              </Modal.Content>
            </Modal>
            <Modal size="tiny" open={uploadLogoOpen}>
              <Modal.Header>COMPANY LOGO PREVIEW</Modal.Header>
              <div style={{ position: "absolute", bottom: 150, left: 240 }}>
                {isLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  />
                ) : null}
              </div>
              <Modal.Content
                style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
              >
                <div
                  style={{
                    height: "100px",
                    width: "100%",
                    minWidth: "250px",
                    position: "relative",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  {imageSrc && (
                    <img
                      src={`data:image/jpeg;base64,${imageSrc}`}
                      alt="Image Preview"
                      className="bomImage"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    />
                  )}
                </div>
              </Modal.Content>
              <Modal.Actions disabled={isLoading}>
                <Button
                  size="tiny"
                  onClick={() => {
                    setUploadLogoOpen(false);
                    setImageData(null);
                    setImageSrc(null);
                  }}
                  disabled={isLoading}
                >
                  CANCEL
                </Button>
                <CompanyImageUploader
                  disabled={isLoading}
                  setImage={getImage}
                  setImageSrc={getImageSrc}
                />
                <Button
                  size="tiny"
                  style={{
                    backgroundColor: "rgb(102, 204, 35)",
                    color: "#fff",
                  }}
                  onClick={() => onUploadCompanyLogo()}
                  disabled={isLoading || !imageData}
                >
                  IMPORT
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal size="mini" open={deleteStockLocationErrorOpen}>
              <div style={{ position: "absolute", bottom: 100, left: 150 }}>
                {isLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  />
                ) : null}
              </div>
              <Modal.Content
                style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
              >
                <div style={{ textAlign: "left", marginBottom: 10 }}>
                  <p style={{ color: "red" }}>Error</p>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  <p>{deleteStockLocationError.message}</p>
                </div>
                {deleteStockLocationError.errors?.length > 0 && (
                  <div style={{ textAlign: "left" }}>
                    <p>{deleteStockLocationError.errors[0]}</p>
                  </div>
                )}
              </Modal.Content>
              <Modal.Actions>
                <Button
                  style={{
                    backgroundColor: "rgb(102, 204, 35)",
                    color: "#fff",
                  }}
                  onClick={() => setDeleteStockLocationErrorOpen(false)}
                >
                  OK
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal
              onClose={() => {
                setAddAssetLocationOpen(false);
              }}
              closeIcon
              size="mini"
              open={addAssetLocationOpen}
            >
              <div style={{ position: "absolute", bottom: 200, left: 150 }}>
                {isLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  />
                ) : null}
              </div>
              <Modal.Header>
                {assetLocationData.id ? "EDIT" : "ADD"} ASSET LOCATION
              </Modal.Header>
              <Modal.Content
                style={{
                  opacity: isLoading ? 0.2 : 1,
                }}
              >
                <Form size="mini">
                  <Form.Field required>
                    <label>Location Name</label>
                    <Input
                      className={
                        assetSaved && !assetLocationData?.locationName
                          ? "error"
                          : ""
                      }
                      value={assetLocationData?.locationName}
                      onChange={(e, data) =>
                        handleAssetLocationChange("locationName", data.value)
                      }
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label>Address</label>
                    <Autocomplete
                      className={
                        assetSaved && !assetLocationData?.locationAddress
                          ? "error"
                          : ""
                      }
                      placeholder=""
                      ref={locationRef}
                      style={{ width: "100%" }}
                      types={["geocode"]}
                      componentRestrictions={{
                        country: countries,
                      }}
                      value={assetLocationData?.locationAddress}
                      onPlaceSelected={(place, inputRef) => {
                        onAddressSelection(place);
                      }}
                      onChange={(e, data) =>
                        handleAssetLocationChange("locationAddress", data)
                      }
                    />
                  </Form.Field>
                  <Grid style={{ marginTop: 15 }}>
                    <Grid.Column width={8} style={{ paddingRight: 5 }}>
                      {assetLocationData.id ? (
                        <Button
                          size="mini"
                          color="youtube"
                          style={{
                            width: "100%",
                            marginLeft: "auto",
                          }}
                          onClick={() => setDeleteAssetLocationOpen(true)}
                        >
                          DELETE
                        </Button>
                      ) : (
                        <Button
                          size="mini"
                          style={{
                            width: "100%",
                            marginLeft: "auto",
                          }}
                          onClick={() => {
                            setAssetLocationData({});
                            setAddAssetLocationOpen(false);
                            setAssetSaved(false);
                          }}
                        >
                          CANCEL
                        </Button>
                      )}
                    </Grid.Column>
                    <Grid.Column width={8} style={{ paddingLeft: 5 }}>
                      <Button
                        size="mini"
                        style={{
                          width: "100%",
                          backgroundColor: "rgb(102, 204, 35)",
                          color: "#fff",
                          marginLeft: "auto",
                        }}
                        onClick={() => {
                          if (assetLocationData && assetLocationData.id > 0) {
                            onUpdateAssetLocation();
                          } else {
                            onAddAssetLocation();
                          }
                        }}
                      >
                        SAVE
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Form>
              </Modal.Content>
            </Modal>
            <Modal
              onClose={() => {
                setAddNotificationOpen(false);
              }}
              closeIcon
              size="mini"
              open={addNotificationOpen}
            >
              <div style={{ position: "absolute", bottom: 200, left: 150 }}>
                {isLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  />
                ) : null}
              </div>
              <Modal.Header>ADD NEW NOTIFICATION RECEIVER</Modal.Header>
              <Modal.Content
                style={{
                  opacity: isLoading ? 0.2 : 1,
                }}
              >
                <Form size="mini">
                  <Form.Field required>
                    <Dropdown
                      className="search-box"
                      placeholder="Quoting Acceptance Email"
                      size="mini"
                      search
                      selection
                      style={{
                        borderColor:
                          !notificationData?.id && notificationSaved
                            ? "#FF0000"
                            : "",
                        width: "100%",
                        fontSize: 13,
                        zIndex: 1002,
                        lineHeight: 1.5,
                      }}
                      options={notifcationUsers}
                      value={notificationData?.id}
                      onChange={(e, data) => {
                        const selectedUser = userListWithCurrent.find(
                          (user) => user.id === data.value
                        );
                        if (selectedUser)
                          setNotificationData({
                            id: data.value,
                            toNotifyEmail: selectedUser.email,
                            notificationType: "Quote Acceptance",
                          });
                      }}
                    />
                    {!notificationData?.id && notificationSaved ? (
                      <div style={{ marginTop: 5, paddingLeft: 3 }}>
                        <span style={{ color: "#FF0000" }}>
                          Email address required
                        </span>
                      </div>
                    ) : null}
                  </Form.Field>
                  <Grid>
                    <Grid.Column
                      width={8}
                      style={{ paddingLeft: 5 }}
                    ></Grid.Column>
                    <Grid.Column width={8} style={{ paddingLeft: 5 }}>
                      <Button
                        size="mini"
                        style={{
                          width: "100%",
                          backgroundColor: "rgb(102, 204, 35)",
                          color: "#fff",
                          marginLeft: "auto",
                          marginTop: 15,
                        }}
                        onClick={() => {
                          onAddNotification();
                        }}
                      >
                        Confirm
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Form>
              </Modal.Content>
            </Modal>
            {accountType === 1 ||
            userType === 5 ||
            userType === 6 ||
            userType === 7 ? (
              <Collapsible
                trigger={
                  userType === 5 || userType === 6 || userType === 7
                    ? "Stock Locations"
                    : `My BOApods ${
                        localStorage.getItem("isBoahubPremium") !== "true"
                          ? "& Stock Locations"
                          : ""
                      }`
                }
                open={boaPodsOpen}
                handleTriggerClick={() => setBoaPodsOpen(!boaPodsOpen)}
              >
                <div style={{ height: "100%" }}>
                  <div style={{ position: "absolute", top: 105, left: 680 }}>
                    {podsLoading && boaPodsOpen ? (
                      <Loader active size="large">
                        Loading BOApods...
                      </Loader>
                    ) : null}
                  </div>
                  <div
                    className="my-boapod-list-scroll"
                    style={{
                      paddingTop: 5,
                      height:
                        podList && podList.length > 4 && accountType === 1
                          ? 400
                          : "100%",
                    }}
                  >
                    {accountType === 1 && (
                      <>
                        <div
                          style={{
                            marginLeft: 10,
                            marginBottom: 10,
                            marginTop: -10,
                            color: "#66cc23",
                          }}
                        >
                          <span>My BOApods</span>
                        </div>
                        <Grid
                          style={{
                            marginLeft: 0,
                            marginRight: 0,
                            paddingLeft: 5,
                          }}
                        >
                          {podList.map((pod, index) => {
                            return (
                              <Grid.Column
                                width={isMobile ? 16 : 8}
                                style={{
                                  paddingLeft: 0,
                                  paddingRight: 15,
                                  paddingBottom: 0,
                                }}
                              >
                                <Card
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                >
                                  <Card.Content
                                    style={{
                                      paddingBottom: 15,
                                      paddingRight: isMobile ? 0 : "1rem",
                                    }}
                                  >
                                    <Grid>
                                      <Grid.Column width={8}>
                                        <Grid>
                                          <Grid.Column
                                            width={16}
                                            style={{
                                              margin: 0,
                                              padding: 0,
                                              paddingLeft: 20,
                                              paddingTop: 10,
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontSize: 13,
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {pod.boApodName}
                                            </label>
                                          </Grid.Column>
                                          <Grid.Column
                                            width={16}
                                            style={{
                                              margin: 0,
                                              padding: 0,
                                              paddingLeft: 20,
                                              paddingTop: 0,
                                            }}
                                          >
                                            <label
                                              style={{
                                                fontSize: 13,
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {pod.trackerIdentNumber}
                                            </label>
                                          </Grid.Column>
                                          <Grid.Column width={16}>
                                            <img
                                              src={boapod}
                                              style={{
                                                height: "auto",
                                                width: "70%",
                                              }}
                                            />
                                          </Grid.Column>
                                        </Grid>
                                      </Grid.Column>
                                      <Grid.Column
                                        width={8}
                                        style={{
                                          paddingRight: isMobile ? 0 : "1rem",
                                        }}
                                      >
                                        <Grid>
                                          <Grid.Column
                                            width={10}
                                            style={{
                                              margin: 0,
                                              padding: 0,
                                              paddingTop: 10,
                                            }}
                                          >
                                            <label style={{ fontSize: 14 }}>
                                              {pod.status === "online"
                                                ? "Available"
                                                : "Not Available"}
                                            </label>
                                          </Grid.Column>
                                          <Grid.Column
                                            width={6}
                                            style={{
                                              margin: 0,
                                              padding: 0,
                                              paddingTop: 10,
                                              textAlign: "right",
                                            }}
                                          >
                                            <Checkbox
                                              className="small-checkbox"
                                              style={{ fontSize: 12 }}
                                              floated="right"
                                              toggle
                                              checked={
                                                pod.status === "online"
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e, data) => {
                                                if (!data.checked) {
                                                  setSelectedPod(index);
                                                  setEditPodOpen(true);
                                                } else {
                                                  handlePodChange(
                                                    "status",
                                                    data.checked,
                                                    index
                                                  );
                                                }
                                              }}
                                            />
                                          </Grid.Column>
                                          <Grid.Column
                                            width={12}
                                            style={{
                                              margin: 0,
                                              padding: 0,
                                            }}
                                          >
                                            {pod.isEdit1 ? (
                                              <Dropdown
                                                size="mini"
                                                selection
                                                style={{
                                                  maxWidth: isMobile
                                                    ? "100%"
                                                    : "115%",
                                                  minWidth: isMobile
                                                    ? "100%"
                                                    : "115%",
                                                  fontSize: 13,
                                                  zIndex: 1002,
                                                }}
                                                options={userListWithCurrent}
                                                value={pod.userId}
                                                onChange={(e, data) =>
                                                  handlePodChange(
                                                    "userId",
                                                    data.value,
                                                    index
                                                  )
                                                }
                                              />
                                            ) : (
                                              <div>
                                                {pod.driverName !== null &&
                                                pod.driverName !== "" ? (
                                                  <label
                                                    style={{ fontSize: 13 }}
                                                  >
                                                    {pod.driverName}
                                                  </label>
                                                ) : (
                                                  <label
                                                    style={{
                                                      fontSize: 13,
                                                      color: "#bebebe",
                                                    }}
                                                  >
                                                    No driver assigned
                                                  </label>
                                                )}
                                              </div>
                                            )}
                                          </Grid.Column>
                                          <Grid.Column
                                            width={4}
                                            style={{
                                              margin: 0,
                                              padding: 0,
                                              paddingRight: 15,
                                              textAlign: "right",
                                            }}
                                          >
                                            <Icon
                                              link
                                              name={
                                                pod.isEdit1 ? "save" : "pencil"
                                              }
                                              style={{
                                                color: pod.isEdit1
                                                  ? "#66cc23"
                                                  : "#000",
                                                fontSize: pod.isEdit1 ? 20 : 16,
                                                marginRight: pod.isEdit1
                                                  ? -1
                                                  : 0,
                                                paddingTop: pod.isEdit1
                                                  ? 1.5
                                                  : 0,
                                              }}
                                              onClick={() => {
                                                if (pod.isEdit1) onSavePod(pod);
                                                handlePodChange(
                                                  "isEdit1",
                                                  !pod.isEdit1,
                                                  index
                                                );
                                              }}
                                            />
                                          </Grid.Column>
                                          <Grid.Column
                                            width={12}
                                            style={{
                                              margin: 0,
                                              padding: 0,
                                              paddingTop: 5,
                                              paddingBottom: 70,
                                            }}
                                          >
                                            {pod.isEdit2 ? (
                                              <Input
                                                size="mini"
                                                style={{
                                                  width: isMobile
                                                    ? "100%"
                                                    : "115%",
                                                }}
                                                value={pod.driverContactNumber}
                                                onChange={(e, data) =>
                                                  handlePodChange(
                                                    "driverContactNumber",
                                                    data.value,
                                                    index
                                                  )
                                                }
                                              />
                                            ) : (
                                              <div>
                                                {pod.driverContactNumber !==
                                                  null &&
                                                pod.driverContactNumber !==
                                                  "" ? (
                                                  <label
                                                    style={{ fontSize: 13 }}
                                                  >
                                                    {pod.driverContactNumber}
                                                  </label>
                                                ) : (
                                                  <label
                                                    style={{
                                                      fontSize: 13,
                                                      color: "#bebebe",
                                                    }}
                                                  >
                                                    No contact number
                                                  </label>
                                                )}
                                              </div>
                                            )}
                                          </Grid.Column>
                                          <Grid.Column
                                            width={4}
                                            style={{
                                              margin: 0,
                                              padding: 0,
                                              paddingTop: 5,
                                              paddingRight: 15,
                                              textAlign: "right",
                                            }}
                                          >
                                            <Icon
                                              link
                                              name={
                                                pod.isEdit2 ? "save" : "pencil"
                                              }
                                              style={{
                                                color: pod.isEdit2
                                                  ? "#66cc23"
                                                  : "#000",
                                                fontSize: pod.isEdit2 ? 20 : 16,
                                                marginRight: pod.isEdit2
                                                  ? -1
                                                  : 0,
                                                paddingTop: pod.isEdit2
                                                  ? 1.5
                                                  : 0,
                                              }}
                                              onClick={() => {
                                                if (pod.isEdit2) onSavePod(pod);
                                                handlePodChange(
                                                  "isEdit2",
                                                  !pod.isEdit2,
                                                  index
                                                );
                                              }}
                                            />
                                          </Grid.Column>
                                        </Grid>
                                      </Grid.Column>
                                    </Grid>
                                  </Card.Content>
                                </Card>
                              </Grid.Column>
                            );
                          })}
                        </Grid>
                      </>
                    )}
                    {localStorage.getItem("isBoahubPremium") !== "true" ? (
                      <>
                        <div
                          style={{
                            marginLeft: 10,
                            marginBottom: 15,
                            marginTop: accountType === 1 ? 40 : 0,
                            color: "#66cc23",
                          }}
                        >
                          <span>My Stock Locations</span>
                        </div>
                        <Grid
                          style={{
                            marginLeft: 0,
                            marginRight: 0,
                            paddingLeft: 5,
                          }}
                        >
                          {stocklocationListFiltered.map((stock, index) => {
                            return (
                              <Grid.Column
                                width={4}
                                style={{
                                  paddingLeft: 0,
                                  paddingRight: 15,
                                  paddingBottom: 0,
                                }}
                              >
                                <Card>
                                  <Card.Content>
                                    <Grid style={{ marginRight: -10 }}>
                                      <Grid.Column
                                        width={11}
                                        style={{
                                          paddingRight: 0,
                                        }}
                                      >
                                        {stock.stockLocationName}
                                      </Grid.Column>
                                      {userType === 4 ||
                                      userType === 7 ||
                                      userType === 10 ? null : (
                                        <Grid.Column
                                          width={5}
                                          style={{
                                            paddingRight: 0,
                                            textAlign: "right",
                                          }}
                                        >
                                          <Icon
                                            style={{ marginRight: 8 }}
                                            link
                                            name="pencil"
                                            onClick={() => {
                                              setStockFormData(stock);
                                              setAddStockLocationOpen(true);
                                            }}
                                          />
                                          <Icon
                                            style={{ marginRight: 8 }}
                                            link
                                            name="trash"
                                            onClick={() => {
                                              setStockFormData(stock);
                                              setDeleteStockLocationOpen(true);
                                            }}
                                          />
                                        </Grid.Column>
                                      )}
                                    </Grid>
                                  </Card.Content>
                                </Card>
                              </Grid.Column>
                            );
                          })}
                          {userType === 2 ||
                          userType === 3 ||
                          userType === 5 ||
                          userType === 6 ? (
                            <Grid.Column
                              width={16}
                              style={{ paddingLeft: 0, paddingRight: 20 }}
                            >
                              <div
                                style={{ textAlign: "right", marginTop: 20 }}
                              >
                                <Button
                                  size="tiny"
                                  style={{
                                    backgroundColor: "#66cc23",
                                    color: "#fff",
                                    marginRight: 10,
                                  }}
                                  onClick={() => setAddStockLocationOpen(true)}
                                >
                                  ADD STOCK LOCATION
                                </Button>
                              </div>
                            </Grid.Column>
                          ) : null}
                        </Grid>
                      </>
                    ) : null}
                  </div>
                </div>
              </Collapsible>
            ) : null}
            <Collapsible trigger="My Asset Locations/Branches" open={false}>
              <div style={{ minHeight: "100%" }}>
                {viewRS23 && (
                  <AssetLocationRS23
                    assetLocationData={assetLocationData}
                    assetLocationId={assetLocationData.id}
                    setViewRS23={setViewRS23}
                    userType={userType}
                  />
                )}
                <Grid style={{ marginLeft: 0, marginRight: 0 }}>
                  <Grid.Row columns={3}>
                    <Grid.Column
                      computer={isMobile ? 16 : 6}
                      style={{ paddingLeft: 0, paddingRight: 15 }}
                    >
                      <Table>
                        <Table.Body>
                          {assetLocationsList.map((data) => {
                            return (
                              <Table.Row key={data.id}>
                                <Table.Cell style={{ padding: 20 }}>
                                  <Grid>
                                    <Grid.Column
                                      width={2}
                                      style={{ padding: 10, paddingBottom: 10 }}
                                    >
                                      <img
                                        src={MarkerImage}
                                        style={{
                                          width: 40,
                                        }}
                                      />
                                    </Grid.Column>
                                    <Grid.Column
                                      width={12}
                                      style={{ padding: 10, paddingBottom: 10 }}
                                    >
                                      <label
                                        style={{
                                          fontSize: 15,
                                          fontWeight: "bolder",
                                        }}
                                      >
                                        {data.locationName}
                                      </label>
                                      {localStorage.getItem("boahubIsRS23") ===
                                      "true" ? (
                                        <div
                                          style={{
                                            fontSize: 13,
                                            paddingTop: 10,
                                          }}
                                        >
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              setAssetLocationData(data);
                                              setViewRS23(true);
                                            }}
                                          >
                                            Show RS23 documents
                                          </a>
                                        </div>
                                      ) : null}
                                    </Grid.Column>
                                    <Grid.Column
                                      width={2}
                                      style={{
                                        padding: 10,
                                        paddingBottom: 10,
                                        textAlign: "right",
                                        paddingRight: 20,
                                      }}
                                    >
                                      {userType === 4 ||
                                      userType === 7 ||
                                      userType === 10 ? null : (
                                        <Icon
                                          link
                                          name="pencil"
                                          onClick={() => {
                                            setAssetLocationData(data);
                                            setAddAssetLocationOpen(true);
                                          }}
                                        />
                                      )}
                                    </Grid.Column>
                                  </Grid>
                                </Table.Cell>
                              </Table.Row>
                            );
                          })}
                        </Table.Body>
                      </Table>
                      <Grid>
                        <Grid.Column
                          width={16}
                          style={{
                            textAlign: "right",
                            paddingTop: 20,
                            paddingRight: 12,
                          }}
                        >
                          {userType === 4 ||
                          userType === 7 ||
                          userType === 10 ? null : (
                            <Button
                              size="mini"
                              style={{
                                width: "40%",
                                backgroundColor: "rgb(102, 204, 35)",
                                color: "#fff",
                                marginLeft: "auto",
                              }}
                              onClick={() => setAddAssetLocationOpen(true)}
                            >
                              Add Asset Location
                            </Button>
                          )}
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </Collapsible>
            <Collapsible trigger="My Organization" open={false}>
              <div style={{ minHeight: "100%" }}>
                {viewTrainingCertificate && (
                  <TrainingCertificate
                    isLoading={isLoading}
                    userData={userData}
                    userId={userData.id}
                    setViewTrainingCertificate={setViewTrainingCertificate}
                    userType={userType}
                    getMyUsersData={getMyUsersData}
                  />
                )}
                <Grid>
                  <Grid.Column
                    width={16}
                    style={{
                      textAlign: "left",
                      paddingTop: 20,
                      paddingRight: 12,
                      paddingBottom: 5,
                    }}
                  >
                    <Button
                      size="medium"
                      style={{
                        width: "18%",
                        backgroundColor: "rgb(102, 204, 35)",
                        color: "#fff",
                        marginLeft: "auto",
                      }}
                      onClick={() => onNewUser()}
                      disabled={
                        userType === 4 || userType === 7 || userType === 10
                      }
                    >
                      Add New User
                    </Button>
                  </Grid.Column>
                </Grid>
                <Grid style={{ marginLeft: 0, marginRight: 0 }}>
                  <Grid.Column
                    computer={isMobile ? 16 : 8}
                    style={{ paddingLeft: 0, paddingRight: 15 }}
                  >
                    {userList.map((data, index) => {
                      return (
                        <Card
                          style={{
                            minHeight: 124,
                            width: "100%",
                            border:
                              data.email === localStorage.getItem("user")
                                ? "1px solid #66cc23"
                                : !data.isAccepted
                                ? "1px solid #FFAD00"
                                : "1px solid #000",
                          }}
                        >
                          <Card.Content style={{ padding: 20 }}>
                            <Grid>
                              <Grid.Column
                                width={1}
                                style={{
                                  padding: 10,
                                  paddingBottom: 10,
                                  paddingLeft: 20,
                                }}
                              >
                                {data.email === localStorage.getItem("user") ? (
                                  <Icon
                                    name="user"
                                    style={{
                                      fontSize: 30,
                                      color: "#66cc23",
                                      marginTop: 23,
                                    }}
                                  />
                                ) : !data.isAccepted ? (
                                  <img
                                    style={{
                                      width: 35,
                                      marginTop: 28,
                                    }}
                                    src={timeIcon}
                                  />
                                ) : null}
                              </Grid.Column>
                              <Grid.Column
                                width={8}
                                style={{
                                  padding: 10,
                                  paddingBottom: 10,
                                  paddingLeft: 30,
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 16,
                                    fontWeight: "bolder",
                                    marginBottom: 8,
                                  }}
                                >
                                  <span>{data.fullName}</span>
                                </div>
                                <div style={{ marginBottom: 8 }}>
                                  <label style={{ fontSize: 14 }}>
                                    {data.email}
                                  </label>
                                </div>
                                {accountType === 1 ? (
                                  <div style={{ marginBottom: 8 }}>
                                    {data.isAccepted && (
                                      <label
                                        style={{
                                          fontSize: 14,
                                          color: "#AFABAB",
                                        }}
                                      >
                                        Hose Assemblies: {data.hoseCount}
                                      </label>
                                    )}
                                  </div>
                                ) : null}
                              </Grid.Column>
                              <Grid.Column
                                width={7}
                                style={{
                                  padding: 10,
                                  paddingBottom: 10,
                                  overflow: "visible",
                                }}
                              >
                                <label style={{ fontSize: 14 }}>
                                  {data.userTypeName}
                                </label>
                                {userType === 4 ||
                                userType === 7 ||
                                userType === 10 ? null : (
                                  <Icon
                                    link
                                    name="pencil"
                                    onClick={() => getUserData(data.id)}
                                    style={{
                                      position: "absolute",
                                      top: 5,
                                      right: 5,
                                      fontSize: 20,
                                    }}
                                  />
                                )}
                                {index === 0 ? (
                                  <div
                                    style={{
                                      marginTop: 35,
                                      color: data.rs23TrainingCertificate?.id
                                        ? "#66cc23"
                                        : "#FFAD00",
                                      cursor: "pointer",
                                      overflow: "visible",
                                    }}
                                    onClick={() => {
                                      setUserData(data);
                                      setViewTrainingCertificate(true);
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: 40,
                                        marginRight: 5,
                                        marginTop: -3.5,
                                      }}
                                      src={
                                        data.rs23TrainingCertificate?.id
                                          ? certificateIcon
                                          : certificateUploadIcon
                                      }
                                    />
                                    {data.rs23TrainingCertificate?.id
                                      ? "View"
                                      : "Upload"}{" "}
                                    training certificate
                                  </div>
                                ) : !data.isAccepted ? (
                                  <div style={{ marginTop: 35 }}>
                                    <Button
                                      color="orange"
                                      size="mini"
                                      style={{
                                        fontSize: 14,
                                        padding: 8,
                                        backgroundColor: "#FFAD00",
                                        marginRight: 10,
                                        minWidth: 120,
                                      }}
                                      onClick={() =>
                                        onResendInviteUser(data.id)
                                      }
                                      loading={
                                        currentId === data.id && isResendloading
                                      }
                                      disabled={
                                        userType === 4 ||
                                        userType === 7 ||
                                        userType === 10
                                      }
                                    >
                                      Resend
                                    </Button>
                                    <Button
                                      color="red"
                                      size="mini"
                                      style={{
                                        fontSize: 14,
                                        padding: 8,
                                        minWidth: 120,
                                      }}
                                      onClick={() =>
                                        onCancelInviteUser(data.id)
                                      }
                                      loading={
                                        currentId === data.id && isCanceloading
                                      }
                                      disabled={
                                        userType === 4 ||
                                        userType === 7 ||
                                        userType === 10
                                      }
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                ) : (
                                  <>
                                    {userType === 4 ||
                                    userType === 7 ||
                                    userType === 10 ? null : (
                                      <div
                                        style={{
                                          marginTop: 35,
                                          color: data.rs23TrainingCertificate
                                            ?.id
                                            ? "#66cc23"
                                            : "#FFAD00",
                                          cursor: "pointer",
                                          overflow: "visible",
                                        }}
                                        onClick={() => {
                                          setUserData(data);
                                          setViewTrainingCertificate(true);
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: 40,
                                            marginRight: 5,
                                            marginTop: -3.5,
                                          }}
                                          src={
                                            data.rs23TrainingCertificate?.id
                                              ? certificateIcon
                                              : certificateUploadIcon
                                          }
                                        />
                                        {data.rs23TrainingCertificate?.id
                                          ? "View"
                                          : "Upload"}{" "}
                                        training certificate
                                      </div>
                                    )}
                                  </>
                                )}
                              </Grid.Column>
                              {/* {!data.isAccepted ? (
                                <>
                                  <Grid.Column
                                    width={8}
                                    style={{
                                      padding: 10,
                                      paddingTop: 3,
                                    }}
                                  >
                                    <label style={{ fontSize: 14 }}>
                                      {data.mobileNumber}
                                    </label>
                                  </Grid.Column>
                                  <Grid.Column
                                    width={8}
                                    style={{
                                      padding: 0,
                                      paddingLeft: 10,
                                      paddingBottom: 10,
                                      paddingRight: 20,
                                    }}
                                  ></Grid.Column>
                                  <Grid.Column
                                    width={16}
                                    style={{
                                      padding: 10,
                                      paddingTop: 3,
                                      textAlign: "center",
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontSize: 14,
                                        color: "#e46d6b",
                                      }}
                                    >
                                      Pending Invitation
                                    </label>
                                  </Grid.Column>
                                </>
                              ) : null} */}
                            </Grid>
                          </Card.Content>
                        </Card>
                      );
                    })}
                  </Grid.Column>
                </Grid>
              </div>
            </Collapsible>
            {userType === 2 || userType === 3 ? (
              <div className="customers-container">
                <Collapsible
                  trigger="My Customers"
                  open={false}
                  style={{ padding: 0 }}
                >
                  <div style={{ minHeight: "100%" }}>
                    <Grid style={{ marginLeft: 0, marginRight: 0 }}>
                      <Grid.Row columns={3}>
                        <Grid.Column
                          width={16}
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <div
                            className="customers-subcontainer"
                            style={{ minHeight: 300 }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: 105,
                                left: 680,
                              }}
                            >
                              {customersLoading ? (
                                <Loader active size="large">
                                  Loading
                                </Loader>
                              ) : null}
                            </div>
                            {customers.map((customer, index) => {
                              return (
                                <div>
                                  <div
                                    style={{
                                      marginBottom: -35,
                                      width: "96%",
                                      zIndex: 1,
                                      position: "relative",

                                      marginRight: "auto",
                                      marginLeft: 22,
                                    }}
                                  >
                                    <Grid
                                      style={{
                                        margin: 0,
                                      }}
                                    >
                                      <Grid.Row style={{ padding: 0 }}>
                                        <Grid.Column
                                          width={10}
                                          style={{
                                            textAlign: "left",
                                            padding: 0,
                                          }}
                                        >
                                          {!onCustomerEdit ||
                                          customer.id < 0 ? (
                                            <span style={{ color: "#fff" }}>
                                              {customer.companyName}
                                            </span>
                                          ) : (
                                            <Input
                                              className="customer-input"
                                              size="tiny"
                                              style={{ padding: 0 }}
                                              value={customer.companyName}
                                              onChange={(e, data) =>
                                                handleCustomerChange(
                                                  "companyName",
                                                  data.value,
                                                  index
                                                )
                                              }
                                            />
                                          )}
                                        </Grid.Column>
                                        <Grid.Column
                                          width={6}
                                          style={{
                                            textAlign: "right",
                                            padding: 0,
                                          }}
                                        >
                                          {customer.id > 0 && (
                                            <Icon
                                              link
                                              name="trash"
                                              style={{ color: "#fff" }}
                                              onClick={() => {
                                                setSelectedCustomer(customer);
                                                setOpenRemoveCustomer(true);
                                              }}
                                            />
                                          )}
                                        </Grid.Column>
                                      </Grid.Row>
                                    </Grid>
                                  </div>
                                  <Collapsible trigger={" "} open={false}>
                                    <div
                                      style={{
                                        minHeight: "100%",
                                        marginTop: 20,
                                        marginBottom: 35,
                                      }}
                                    >
                                      <Grid
                                        style={{
                                          marginLeft: 0,
                                          marginRight: 20,
                                        }}
                                      >
                                        <Grid.Row style={{ padding: 5 }}>
                                          {pricingData.allowCustomerDiscounts &&
                                          customer.id > 0 ? (
                                            <>
                                              <Grid.Column width={16}>
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: 16,
                                                  }}
                                                >
                                                  Discount
                                                </span>
                                              </Grid.Column>
                                              <Grid.Column
                                                width={16}
                                                style={{
                                                  marginBottom: 10,
                                                  marginTop: 3,
                                                }}
                                              >
                                                {!onCustomerEdit ||
                                                customer.id < 0 ? (
                                                  <span
                                                    style={{
                                                      fontSize: 16,
                                                    }}
                                                  >
                                                    {Number(
                                                      customer.discountPercent
                                                    )}
                                                    %
                                                  </span>
                                                ) : (
                                                  <>
                                                    <Input
                                                      className="contact-input"
                                                      size="tiny"
                                                      style={{ padding: 0 }}
                                                      value={
                                                        customer.discountPercent
                                                      }
                                                      onChange={(e, data) =>
                                                        handleCustomerChange(
                                                          "discountPercent",
                                                          data.value,
                                                          index
                                                        )
                                                      }
                                                    />
                                                    <span
                                                      style={{
                                                        fontSize: 16,
                                                      }}
                                                    >
                                                      %
                                                    </span>
                                                  </>
                                                )}
                                              </Grid.Column>
                                            </>
                                          ) : null}
                                          <Grid.Column width={5}>
                                            <span
                                              style={{
                                                fontWeight: "bolder",
                                                fontSize: 18,
                                              }}
                                            >
                                              Contact Name
                                            </span>
                                          </Grid.Column>
                                          <Grid.Column width={5}>
                                            <span
                                              style={{
                                                fontWeight: "bolder",
                                                fontSize: 18,
                                              }}
                                            >
                                              Phone Number
                                            </span>
                                          </Grid.Column>
                                          <Grid.Column width={6}>
                                            <span
                                              style={{
                                                fontWeight: "bolder",
                                                fontSize: 18,
                                              }}
                                            >
                                              Email Address
                                            </span>
                                          </Grid.Column>
                                        </Grid.Row>
                                        {customer?.customerContacts?.map(
                                          (contact, index) => {
                                            return (
                                              <Grid.Row
                                                columns={4}
                                                style={{ padding: 5 }}
                                              >
                                                <Grid.Column width={5}>
                                                  {!onCustomerEdit ? (
                                                    <span>
                                                      {contact?.contactName}
                                                    </span>
                                                  ) : (
                                                    <Input
                                                      className="contact-input"
                                                      size="tiny"
                                                      style={{ padding: 0 }}
                                                      value={
                                                        contact?.contactName
                                                      }
                                                      onChange={(e, data) =>
                                                        handleCustomerContactChange(
                                                          "contactName",
                                                          data.value,
                                                          customer?.id,
                                                          index
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </Grid.Column>
                                                <Grid.Column width={5}>
                                                  {!onCustomerEdit ? (
                                                    <span>
                                                      {contact?.contactNumber}
                                                    </span>
                                                  ) : (
                                                    <Input
                                                      className="contact-input"
                                                      size="tiny"
                                                      style={{ padding: 0 }}
                                                      value={
                                                        contact?.contactNumber
                                                      }
                                                      onChange={(e, data) =>
                                                        handleCustomerContactChange(
                                                          "contactNumber",
                                                          data.value,
                                                          customer?.id,
                                                          index
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </Grid.Column>
                                                <Grid.Column width={5}>
                                                  {!onCustomerEdit ? (
                                                    <span>
                                                      {contact?.email}
                                                    </span>
                                                  ) : (
                                                    <Input
                                                      className="contact-input"
                                                      size="tiny"
                                                      style={{ padding: 0 }}
                                                      value={contact?.email}
                                                      onChange={(e, data) =>
                                                        handleCustomerContactChange(
                                                          "email",
                                                          data.value,
                                                          customer?.id,
                                                          index
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </Grid.Column>
                                                <Grid.Column width={1}>
                                                  <Icon
                                                    link
                                                    name="trash"
                                                    onClick={() => {
                                                      setSelectedContact(
                                                        contact
                                                      );
                                                      setOpenRemoveContact(
                                                        true
                                                      );
                                                    }}
                                                  />
                                                </Grid.Column>
                                              </Grid.Row>
                                            );
                                          }
                                        )}
                                      </Grid>
                                    </div>
                                  </Collapsible>
                                </div>
                              );
                            })}
                          </div>
                        </Grid.Column>
                        <Grid.Column
                          width={16}
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <div
                            style={{
                              textAlign: "right",
                              marginTop: 20,
                              marginBottom: 20,
                            }}
                          >
                            <Button
                              onClick={() => {
                                setIsAddCompany(false);
                                setContactSaved(false);
                                setOnCustomerEdit(false);
                                setAddContactOpen(true);
                              }}
                            >
                              ADD NEW
                            </Button>
                            {!onCustomerEdit ? (
                              <Button onClick={() => setOnCustomerEdit(true)}>
                                EDIT
                              </Button>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    getCustomersData();
                                    setOnCustomerEdit(false);
                                  }}
                                >
                                  CANCEL EDIT
                                </Button>
                                <Button
                                  onClick={() => onSaveCustomersAndContacts()}
                                >
                                  SAVE
                                </Button>
                              </>
                            )}
                            <Button
                              onClick={() => {
                                setOnCustomerEdit(false);
                                setCsvUploadOpen(true);
                              }}
                            >
                              CSV UPLOAD
                            </Button>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </Collapsible>
              </div>
            ) : null}
            {userType === 2 || userType === 3 ? (
              <div className="logo-container">
                <Collapsible trigger="My Company Logo" open={false}>
                  <div style={{ minHeight: "100%" }}>
                    <Grid>
                      <Grid.Column
                        width={16}
                        style={{ marginLeft: 30, marginTop: 20 }}
                      >
                        <div
                          style={{
                            height: "120px",
                            width: "100%",
                            minWidth: "250px",
                            position: "relative",
                            marginBottom: 45,
                            textAlign: "left",
                          }}
                        >
                          {localStorage.getItem("relatedCompanyLogoUrl") &&
                            localStorage.getItem("relatedCompanyLogoUrl") !==
                              "null" && (
                              <div
                                style={{
                                  height: 120,
                                  width: imageWidth ? imageWidth : 250,
                                }}
                              >
                                <img
                                  ref={imgRef}
                                  src={localStorage.getItem(
                                    "relatedCompanyLogoUrl"
                                  )}
                                  alt="Image Preview"
                                  className="logoImage"
                                  style={{
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                  }}
                                  onLoad={getImageWidth}
                                />
                                <Button
                                  circular
                                  icon="settings"
                                  style={{
                                    position: "absolute",
                                    left: imageWidth ? imageWidth - 10 : 250,
                                    top: -12,
                                    cursor: "pointer",
                                    padding: "4px 9px 7px 9px",
                                  }}
                                  onClick={() => setRemoveLogoOpen(true)}
                                >
                                  x
                                </Button>
                              </div>
                            )}
                        </div>
                        <div style={{ marginLeft: 30 }}>
                          <span>
                            Only accepts JPEG, PNG, BMP formats.
                            <br />
                            Maximum file size should be 5MB.
                          </span>
                        </div>
                      </Grid.Column>
                      <Grid.Column width={16} style={{ margin: 30 }}>
                        <div style={{ textAlign: "right", marginTop: 20 }}>
                          <Button
                            size="tiny"
                            style={{
                              backgroundColor: "#66cc23",
                              color: "#fff",
                              marginRight: 10,
                            }}
                            onClick={() => setUploadLogoOpen(true)}
                          >
                            UPLOAD LOGO{" "}
                            <Icon
                              style={{ marginLeft: 5 }}
                              name="cloud upload"
                            />
                          </Button>
                        </div>
                      </Grid.Column>
                    </Grid>
                  </div>
                </Collapsible>
              </div>
            ) : null}
            {userType === 2 || userType === 3 ? (
              <div className="logo-container">
                <Collapsible trigger="My Pricing" open={false}>
                  <div style={{ minHeight: "100%" }}>
                    <Card
                      style={{
                        width: "100%",
                        marginBottom: 10,
                      }}
                    >
                      <Card.Content style={{ padding: 10 }}>
                        <Grid style={{ margin: 0, marginLeft: 0 }}>
                          <Grid.Column width={16}>
                            <Checkbox
                              checked={pricingData.useBoaRrp}
                              style={{ fontSize: 17 }}
                              className="pricing-checkbox"
                              label="Use BOA RRP"
                              onChange={(e, data) => {
                                handlePricingChange("useBoaRrp", true);
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column width={16} style={{ paddingTop: 0 }}>
                            <Checkbox
                              checked={!pricingData.useBoaRrp}
                              style={{ fontSize: 17 }}
                              className="pricing-checkbox"
                              label="Set Custom Global Markup"
                              onChange={(e, data) => {
                                handlePricingChange("useBoaRrp", false);
                              }}
                            />
                          </Grid.Column>
                          {!pricingData.useBoaRrp ? (
                            <Grid.Column width={8} style={{ paddingTop: 0 }}>
                              {!editCGM ? (
                                <div style={{ paddingLeft: 33 }}>
                                  <Grid style={{ margin: 0, padding: 0 }}>
                                    <Grid.Column
                                      width={2}
                                      style={{ margin: 0, padding: 0 }}
                                    >
                                      <span
                                        style={{ paddingLeft: 17, width: 500 }}
                                      >
                                        {Number(pricingData.customGlobalMarkup)}
                                      </span>
                                    </Grid.Column>
                                    <Grid.Column
                                      width={14}
                                      style={{
                                        margin: 0,
                                        padding: 0,
                                        paddingLeft: 17,
                                      }}
                                    >
                                      <span>%</span>
                                    </Grid.Column>
                                  </Grid>
                                </div>
                              ) : (
                                <div style={{ paddingLeft: 33 }}>
                                  <Grid style={{ margin: 0, padding: 0 }}>
                                    <Grid.Column
                                      width={2}
                                      style={{ margin: 0, padding: 0 }}
                                    >
                                      <Input
                                        type="number"
                                        min={0}
                                        max={100}
                                        style={{
                                          width: 80,
                                          marginTop: -10,
                                          paddingRight: 0,
                                        }}
                                        value={Number(
                                          pricingData.customGlobalMarkup
                                        )}
                                        onChange={(e, data) =>
                                          handlePricingChange(
                                            "customGlobalMarkup",
                                            data.value
                                          )
                                        }
                                      />
                                    </Grid.Column>
                                    <Grid.Column
                                      width={14}
                                      style={{
                                        margin: 0,
                                        padding: 0,
                                        paddingLeft: 17,
                                      }}
                                    >
                                      <span>%</span>
                                    </Grid.Column>
                                  </Grid>
                                </div>
                              )}
                            </Grid.Column>
                          ) : null}
                          {!pricingData.useBoaRrp ? (
                            <Grid.Column width={8} style={{ paddingTop: 0 }}>
                              {!editCGM ? (
                                <div style={{ textAlign: "right" }}>
                                  <Button
                                    size="tiny"
                                    onClick={() => setEditCGM(true)}
                                  >
                                    EDIT
                                  </Button>
                                </div>
                              ) : (
                                <div style={{ textAlign: "right" }}>
                                  <Button
                                    size="tiny"
                                    style={{
                                      backgroundColor: "#66cc23",
                                      color: "#fff",
                                      marginRight: 10,
                                    }}
                                    onClick={() => {
                                      onSubmitCustomPricing(pricingData);
                                      setEditCGM(false);
                                    }}
                                  >
                                    SAVE
                                  </Button>
                                  <Button
                                    size="tiny"
                                    onClick={() => {
                                      getCustomPricingData();
                                      setEditCGM(false);
                                    }}
                                  >
                                    CANCEL
                                  </Button>
                                </div>
                              )}
                            </Grid.Column>
                          ) : null}
                        </Grid>
                      </Card.Content>
                    </Card>
                    <Card style={{ width: "100%", marginTop: 0 }}>
                      <Card.Content style={{ padding: 10 }}>
                        <Grid style={{ margin: 0, paddingTop: 0 }}>
                          <Grid.Column width={16}>
                            <Checkbox
                              checked={pricingData.allowCustomerDiscounts}
                              style={{ fontSize: 17 }}
                              className="pricing-checkbox"
                              label="Allow Customer Specific Discounts"
                              onChange={(e, data) => {
                                handlePricingChange(
                                  "allowCustomerDiscounts",
                                  data.checked
                                );
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column width={16} style={{ paddingTop: 0 }}>
                            <span style={{ color: "red", paddingLeft: 55 }}>
                              NOTE:
                            </span>
                            <i>
                              <span
                                style={{
                                  paddingLeft: 5,
                                  fontSize: 14,
                                }}
                              >
                                Customer specific discounts are set against
                                specific customer within My Customers section.
                                These discounts are not applied to labour and
                                travel rates.
                              </span>
                            </i>
                          </Grid.Column>
                        </Grid>
                      </Card.Content>
                    </Card>
                    <Card
                      style={{ width: "100%", minHeight: 100, marginTop: 0 }}
                    >
                      <Card.Content style={{ padding: 10 }}>
                        <Grid style={{ margin: 0, paddingTop: 10 }}>
                          <Grid.Column width={16} style={{ paddingTop: 0 }}>
                            <span style={{ paddingLeft: 33 }}>Labour (hr)</span>
                            <span style={{ paddingLeft: 30 }}>$</span>
                            {!editPricing ? (
                              <span>{Number(pricingData.labourFee)}</span>
                            ) : (
                              <Input
                                type="number"
                                min={0}
                                max={100}
                                className="contact-input"
                                size="tiny"
                                style={{
                                  padding: 0,
                                  paddingLeft: 3,
                                  width: 70,
                                }}
                                value={pricingData.labourFee}
                                onChange={(e, data) =>
                                  handlePricingChange("labourFee", data.value)
                                }
                              />
                            )}
                          </Grid.Column>
                          <Grid.Column width={16} style={{ paddingTop: 0 }}>
                            <span style={{ paddingLeft: 33 }}>
                              Travel ({userCountryCode === "US" ? "mi" : "km"})
                            </span>
                            <span style={{ paddingLeft: 30 }}>$</span>
                            {!editPricing ? (
                              <span>{Number(pricingData.travel)}</span>
                            ) : (
                              <Input
                                type="number"
                                min={0}
                                max={100}
                                className="contact-input"
                                size="tiny"
                                style={{
                                  padding: 0,
                                  paddingLeft: 3,
                                  width: 70,
                                  textAlign: "center",
                                }}
                                value={pricingData.travel}
                                onChange={(e, data) =>
                                  handlePricingChange("travel", data.value)
                                }
                              />
                            )}
                          </Grid.Column>
                          <Grid.Column width={3} style={{ paddingTop: 10 }}>
                            <Checkbox
                              disabled={!editPricing}
                              checked={pricingData.useEach}
                              style={{ fontSize: 17 }}
                              className="pricing-checkbox"
                              label="Crimp Fee (ea)"
                              onChange={(e, data) => {
                                handlePricingChange("useEach", true);
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column width={13} style={{ paddingTop: 10 }}>
                            <span style={{ color: "red" }}>NOTE:</span>
                            <i>
                              <span
                                style={{
                                  paddingLeft: 5,
                                  fontSize: 14,
                                }}
                              >
                                2 x crimp fees will added to the value of the
                                RRP $ of the Hose & Fittings
                              </span>
                            </i>
                          </Grid.Column>
                          {pricingData.useEach ? (
                            <Grid.Column width={16} style={{ paddingTop: 0 }}>
                              {!editPricing ? (
                                <div>
                                  <span style={{ paddingLeft: 50 }}>$</span>
                                  <span style={{ paddingLeft: 5 }}>
                                    {Number(pricingData.crimpFeeEach)}
                                  </span>
                                </div>
                              ) : (
                                <div style={{ paddingLeft: 33 }}>
                                  <span style={{ paddingRight: 5 }}>$</span>
                                  <Input
                                    type="number"
                                    min={0}
                                    max={100}
                                    style={{ width: 80, marginTop: -10 }}
                                    value={pricingData.crimpFeeEach}
                                    onChange={(e, data) =>
                                      handlePricingChange(
                                        "crimpFeeEach",
                                        data.value
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </Grid.Column>
                          ) : null}
                          <Grid.Column width={3} style={{ paddingTop: 0 }}>
                            <Checkbox
                              disabled={!editPricing}
                              checked={!pricingData.useEach}
                              style={{ fontSize: 17 }}
                              className="pricing-checkbox"
                              label="Crimp Fee Markup (%)"
                              onChange={(e, data) => {
                                handlePricingChange("useEach", false);
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column width={13} style={{ paddingTop: 0 }}>
                            <span style={{ color: "red" }}>NOTE:</span>
                            <i>
                              <span
                                style={{
                                  paddingLeft: 5,
                                  fontSize: 14,
                                }}
                              >
                                Will add the % on the value of the RRP $ of the
                                Hose & Fittings
                              </span>
                            </i>
                          </Grid.Column>
                          {!pricingData.useEach ? (
                            <Grid.Column width={8} style={{ paddingTop: 0 }}>
                              {!editPricing ? (
                                <div>
                                  <span style={{ paddingLeft: 50 }}>
                                    {Number(pricingData.crimpFeeMarkupPercent)}
                                  </span>
                                  <span style={{ paddingLeft: 5 }}>%</span>
                                </div>
                              ) : (
                                <div style={{ paddingLeft: 33 }}>
                                  <Input
                                    type="number"
                                    min={0}
                                    max={100}
                                    style={{ width: 80, marginTop: -10 }}
                                    value={pricingData.crimpFeeMarkupPercent}
                                    onChange={(e, data) =>
                                      handlePricingChange(
                                        "crimpFeeMarkupPercent",
                                        data.value
                                      )
                                    }
                                  />
                                  <span style={{ paddingLeft: 5 }}>%</span>
                                </div>
                              )}
                            </Grid.Column>
                          ) : null}
                          <Grid.Column
                            width={16}
                            style={{ paddingTop: 0, marginTop: -20 }}
                          >
                            {!editPricing ? (
                              <div style={{ textAlign: "right" }}>
                                <Button
                                  size="tiny"
                                  onClick={() => setEditPricing(true)}
                                >
                                  EDIT
                                </Button>
                              </div>
                            ) : (
                              <div style={{ textAlign: "right" }}>
                                <Button
                                  size="tiny"
                                  style={{
                                    backgroundColor: "#66cc23",
                                    color: "#fff",
                                    marginRight: 10,
                                  }}
                                  onClick={() => {
                                    onSubmitCustomPricing(pricingData);
                                    setEditPricing(false);
                                  }}
                                >
                                  SAVE
                                </Button>
                                <Button
                                  size="tiny"
                                  onClick={() => {
                                    getCustomPricingData();
                                    setEditPricing(false);
                                  }}
                                >
                                  CANCEL
                                </Button>
                              </div>
                            )}
                          </Grid.Column>
                        </Grid>
                      </Card.Content>
                    </Card>
                  </div>
                </Collapsible>
              </div>
            ) : null}
            {userType === 2 || userType === 3 ? (
              <Collapsible trigger="My Integrations" open={false}>
                <div style={{ minHeight: "100%" }}>
                  <XeroIntegrationComponent
                    onIntegrationComplete={handleIntegrationComplete}
                    xeroAuthUrl={xeroAuthUrl}
                  />
                </div>
              </Collapsible>
            ) : null}
            {localStorage.getItem("isBoahubPremium") === "true" &&
            localStorage.getItem("userType") !== "4" ? (
              <Collapsible trigger="My Notification" open={false}>
                <div style={{ minHeight: "100%" }}>
                  <Button
                    size="small"
                    style={{
                      width: "15%",
                      backgroundColor: "rgb(102, 204, 35)",
                      color: "#fff",
                      marginLeft: "auto",
                    }}
                    onClick={() => {
                      setNotificationSaved(false);
                      setNotificationData(null);
                      setAddNotificationOpen(true);
                    }}
                  >
                    Add New Receiver
                  </Button>
                </div>
                <div
                  style={{ minHeight: "100%", marginTop: 20, paddingLeft: 2 }}
                >
                  <span>Quote Acceptance Email</span>
                  <hr />
                </div>
                <div
                  style={{ minHeight: "100%", marginTop: 20, paddingLeft: 2 }}
                >
                  <Grid>
                    {notificationList.map((data, index) => {
                      const userDetails = userListWithCurrent.find(
                        (user) => user.email === data.toNotifyEmail
                      );
                      return (
                        <>
                          <Grid.Column width={8} style={{ paddingBottom: 0 }}>
                            <Card style={{ width: "100%" }}>
                              <Card.Content style={{ height: 50 }}>
                                <Grid>
                                  <Grid.Column
                                    width={2}
                                    style={{ paddingTop: 8 }}
                                  >
                                    <img
                                      style={{
                                        width: 35,
                                      }}
                                      src={emailIcon}
                                    />
                                  </Grid.Column>
                                  <Grid.Column
                                    width={5}
                                    style={{ paddingTop: 12 }}
                                  >
                                    <span>{userDetails?.fullName}</span>
                                  </Grid.Column>
                                  <Grid.Column
                                    width={8}
                                    style={{ paddingTop: 12 }}
                                  >
                                    <span>{data?.toNotifyEmail}</span>
                                  </Grid.Column>
                                  <Grid.Column
                                    width={1}
                                    style={{
                                      paddingRight: 10,
                                      paddingLeft: 0,
                                      paddingTop: 12,
                                    }}
                                  >
                                    <Icon
                                      style={{ color: "#EF0E0E" }}
                                      link
                                      name="trash"
                                      onClick={() => {
                                        setNotificationData(data);
                                        setDeleteNotificationOpen(true);
                                      }}
                                    />
                                  </Grid.Column>
                                </Grid>
                              </Card.Content>
                            </Card>
                          </Grid.Column>
                          <Grid.Column
                            width={8}
                            style={{ paddingBottom: 0 }}
                          ></Grid.Column>
                        </>
                      );
                    })}
                  </Grid>
                </div>
              </Collapsible>
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
};

export default Settings;
